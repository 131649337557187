import React, { lazy, Suspense } from "react";
import { AppContextProvider } from "@app/context";
const AppLayout = lazy(() => import("@app/routes").then(({
    AppLayout
}) => ({
    default: AppLayout
})));
import { Container, Loader } from "@common/components";
import { createRoot, Root } from "react-dom/client";

const container: HTMLDivElement | null = document.querySelector("#app");
if (!container) throw Error("no div with id app 😢");
const root: Root = createRoot(container);

root.render(
    <React.StrictMode>
        <AppContextProvider>
            <Suspense fallback={(
                <Container xs="0">
                    <Loader />
                </Container>
            )}>
                <AppLayout />
            </Suspense>
        </AppContextProvider>
    </React.StrictMode>
);

import React, { createContext, Dispatch, FunctionComponent, useMemo, useReducer } from "react";
import { appContextReducer, initAppState, INIT_APP_STATE } from "./AppContext.reducer";
import { IAppState, TAppAction } from "./AppContext.types";
import { TContextProviderProps } from "@common/types/Contexts.types";
import { CheckUser } from "@auth/components";
import { theme, palettes, ThemeTypeMode } from "@theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "@app/components";
import { useLocalStorage } from "@common/hooks";


export const AppStateContext = createContext<IAppState>(INIT_APP_STATE);
export const AppDispatchContext = createContext<Dispatch<TAppAction>>(() => null);

export const AppContextProvider: FunctionComponent<TContextProviderProps> = ({
    ...props
}) => {
    const {
        getCurrent,
        setCurrent
    } = useLocalStorage<ThemeTypeMode>({
        key: "theme"
    });
    const [state, dispatch] = useReducer(
        appContextReducer,
        {theme: getCurrent() || "light"},
        initAppState
    );
    const selectedTheme: typeof theme = useMemo(() => {
        if (!getCurrent()) setCurrent(state.theme);
        return {...Object.assign(theme, {
            mode: state.theme,
            palette: palettes[state.theme]
        })};
    }, [state.theme, getCurrent, setCurrent]);
    return (
        <ThemeProvider theme={selectedTheme}>
            <GlobalStyle bg={selectedTheme.palette.background} />
            <AppDispatchContext.Provider value={dispatch}>
                <AppStateContext.Provider value={state}>
                    <CheckUser {...props}>
                        {props.children}
                    </CheckUser>
                </AppStateContext.Provider>
            </AppDispatchContext.Provider>
        </ThemeProvider>
    );
};

import { useAppState } from "@app/context";
import { appRoutes } from "@app/routes";
import { ILayoutRoute, TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";
import { Navigate, Outlet } from "react-router-dom";


export const AuthRouteWrapper: FunctionComponent<TLayoutProps & Partial<ILayoutRoute>> = ({
    protect,
    ...props
}) => {
    const appState = useAppState();
    return (
        <React.Fragment>
            {/* <Outlet /> */}
            {protect
                ? appState.authenticated 
                    ? <Outlet />
                    : <Navigate to={appRoutes.home.url} replace />
                : <Outlet />
            }
        </React.Fragment>
    );
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IGamesApiEditionFormInputs, TGamesApiEditionFormProps } from "./GamesApiEditionForm.types";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { Form, IField, IFormButton } from "@common/components";
import { TFetchGamesApiEditionsData } from "@games-api/hooks/useFetchGamesApiEditions/useFetchGamesApiEditions.types";
import { useFetchGamesApiEditions } from "@games-api/hooks/useFetchGamesApiEditions";

export const GamesApiEditionForm: FunctionComponent<TGamesApiEditionFormProps> = ({
    edition,
    refresh,
    _tournamentId,
    closeForm,
    ...props
}) => {
    const [currentValues, setCurrentValues] = useState<
        DeepPartialSkipArrayKey<IGamesApiEditionFormInputs>
    >();
    const [editionData, setEditionData] = useState<TFetchGamesApiEditionsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: editionCode,
        loading: loading
    } = useFetchGamesApiEditions(editionData);
    const fields: IField<IGamesApiEditionFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            name: "name",
            mode: "input",
            defaultValue: edition?.name || "",
            validations: {
                required: "name is required 😐"
            },
            modeProps: {
                placeholder: "EDITION NAME"
            }
        }];
        return _fields;
    }, [edition]);
    const hasChanged = useMemo(() => {
        if (!edition) return true;
        if (!currentValues) return true;
        if (currentValues["name"] !== edition.name) return true;
        return false;
    }, [edition, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: edition ? "update" : "create",
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            },
            disabled: !hasChanged,
            style: {
                marginLeft: 0
            },
        }];
        _buttons.push({
            row: 1,
            label: "cancel",
            size: "sm",
            caps: true,
            type: "button",
            nature: "decline",
            style: {
                marginRight: 0
            },
            onClick: (event) => closeForm && closeForm(false)
        });
        return _buttons;
    }, [edition, loading, hasChanged, closeForm]);
    const onSubmit: SubmitHandler<IGamesApiEditionFormInputs> = useCallback(
        async(payload) => {
            if (!edition) {
                setEditionData((prev) => ({
                    ...prev,
                    data: {
                        ...payload,
                        _tournamentId: _tournamentId
                    },
                    trigger: true
                }));
                return;
            }
            if (hasChanged) {
                setEditionData((prev) => ({
                    ...prev,
                    method: "PATCH",
                    data: payload,
                    _editionId: edition._id,
                    trigger: true
                }));
            }
        }, [edition, _tournamentId, hasChanged]);
    useEffect(() => {
        if (!editionCode) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (editionCode === 201) {
            return refresh && refresh();
        }
    }, [editionCode, refresh]);
    return (
        <React.Fragment>
            <Form
                aria-label="edition-form"
                name={`edition form ${edition?.id || "new edition"}`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}
                {...props} />
        </React.Fragment>
    );
};
import { useEffect, useState } from "react";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { IFetchEditions, TEditionBody, TEditionResponse, TFetchEditionsData } from "./useFetchEditions.types";

export function useFetchEditions({
    _editionId,
    method,
    data,
    trigger,
    successMessage=`edition successfuly ${method === "POST" 
        ? "created" 
        : "updated"} ⚽`,
    inform=true,
    options,
    ignoreHeaders,
    qs,
    ...props
}: TFetchEditionsData): IFetchEditions {
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchData<TEditionBody>>({
        endpointURI: _editionId
            ? endpoints.editions.buildUrl({
                _editionId: _editionId
            })
            : endpoints.editions.buildUrl({
                qs: qs
            }),
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage,
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TEditionBody, TEditionResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _editionId
                    ? endpoints.editions.buildUrl({
                        _editionId: _editionId
                    })
                    : endpoints.editions.buildUrl({
                        qs: qs
                    }),
                method: method,
                auth: method !== "GET",
                successMessage: successMessage,
                options: options,
                inform: inform,
                data: data,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _editionId,
        method,
        successMessage,
        options,
        inform,
        data,
        qs
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};

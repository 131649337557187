export type TEndpointName = "users" 
    | "whoAmI"
    | "logins"
    | "federations"
    | "countries"
    | "federationAvatars"
    | "teams"
    | "teamAvatars"
    | "competitions"
    | "competitionAvatars"
    | "editions"
    | "matches"
    | "tournaments"
    | "getTournaments"
    | "getGamesApiEditions"
    | "gamesApiEditions"
    | "getGamesApiGames"
    | "gamesApiGames"
    | "getGamesApiGameMatches"
    | "gamesApiGameMatches"
    | "getGamesApiPrizes"
    | "gamesApiPrizes"
    | "consortiumMemberships";

export const endpointsURI: Record<TEndpointName, string> = {
    users: "users",
    whoAmI: "who-am-i",
    logins: "logins",
    federations: "federations",
    countries: "countries",
    federationAvatars: "federation-avatars",
    teams: "teams",
    teamAvatars: "team-avatars",
    competitions: "competitions",
    competitionAvatars: "competition-avatars",
    editions: "editions",
    matches: "matches",
    tournaments: "games-api/tournaments",
    getTournaments: "tournaments",
    getGamesApiEditions: "editions",
    gamesApiEditions: "games-api/editions",
    getGamesApiGames: "games",
    gamesApiGames: "games-api/games",
    getGamesApiGameMatches: "game-matches",
    gamesApiGameMatches: "games-api/game-matches",
    getGamesApiPrizes: "prizes",
    gamesApiPrizes: "games-api/prizes",
    consortiumMemberships: "consortium-memberships"
};

import styled from "styled-components";
import { TNavbarProps } from "./Navbar.types";

export const StyledNavbar = styled.nav<TNavbarProps>`
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
    width: 100%;
    height: 4rem;
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.palette.backgroundNav};
    color: ${props => props.theme.palette.itemLightText};
    justify-content: center;
    z-index: 2000
`;
import { colors, TColorLabel } from "@theme";
import { TCoordinates, TFrame, TPath, TPatternName } from "./Snake.types";
import { snakePatterns } from "./Snake.patterns";

export class Snake {
    path?: TPath[];
    current?: number[];
    keyframes?: Record<string, TFrame[]>;


    constructor(
        kind: TPatternName = "circle", 
        size = 5,
        colors: TColorLabel[],
        length: number
    ) {
        this.start(
            snakePatterns[kind].board, 
            snakePatterns[kind].order, 
            colors, 
            length
        ); 
        this.keyframes = this.buildFrames(size);
    };

    generateColors(
        colors: TColorLabel[],
        length: number
    ) {
        function _nextColor(current: number): number {
            if (current === colors.length - 1) {
                return 0;
            }
            return current + 1;
        }
        let currentColorIndex = -1;
        const orderedColors: TColorLabel[] = [];
        for (let color = 0; color < length; color++) {
            currentColorIndex = _nextColor(currentColorIndex);
            orderedColors.push(colors[currentColorIndex]);
        }
        return orderedColors;
    };

    start(
        board: (number | string)[][], 
        order: number[],
        colors: TColorLabel[],
        length: number
    ): void {
        let position = -1;
        const path: TPath[] = [];
        const orderedColors: TColorLabel[] = this.generateColors(colors, length);
        for (let x = 0; x < board.length; x++) {
            for (let y = 0; y < board[0].length; y++) {
                position += 1;
                if (board[x][y] === 0) continue;
                path.push({
                    position: position,
                    color: order.slice(0,length).includes(position)
                        ? orderedColors[
                            order.findIndex(o => o === position)
                        ] as TColorLabel
                        : "transparent",
                    order: order.findIndex(o => o === position)
                });
            }
        }
        this.path = path.sort((a, b) => a.order - b.order);
        this.current = this.path.slice(0, length).map(p => p.position);
    };

    advance() {
        if (!this.current || !this.path) return;
        let nextColor: TColorLabel = "transparent";
        let prevColor: TColorLabel = colors.white30 as TColorLabel;
        this.current.forEach((position: number, index: number) => {
            const path = this.path?.find(p => p.position === position);
            if (path) {
                nextColor = path.color;
                path.color = prevColor;
                prevColor = nextColor;
                return;
            }
        });
        this.path.forEach((p: TPath, index: number) => {
            if (this.current?.includes(p.position)) {
                return;
            } else {
                p.color = "transparent";
            }
        });
        
        const newSnake: number[] = this.current
            ? [...this.current.slice(1)]
            : [];
        const lastPosition: number = this.path.findIndex(
            p => p.position === newSnake[newSnake.length - 1]
        );
        const nextPosition: number = lastPosition === this.path.length - 1
            ? 0
            : lastPosition + 1;

        const nextPath: TPath = this.path[nextPosition];
        nextPath.color = prevColor;
        newSnake.push(this.path[nextPosition].position);
        this.current = newSnake;
    };

    getLabels(): string[] {
        const labels: string[] = [];
        const step: number = 100 / 16;
        let label = 0;
        while (label <= 100) {
            labels.push(`${label}%`);
            label += step;
        }
        return labels;
    }

    getCoordinates(size: number): TCoordinates[] {
        const coordinates: TCoordinates[] = [];
        const [start, stop, step] = [size * -3, size * 4, size];
        let position = 0;
        for (let yIndex = start; yIndex < stop; yIndex+=step) {
            for (let xIndex = start; xIndex < stop; xIndex+=step) {
                if (this.path?.map(p => p.position).includes(position)) {
                    coordinates.push({ 
                        coords: `${xIndex}px ${yIndex}px`,
                        position: position 
                    });
                }
                position += 1;
            }
        }
        return coordinates;
    }

    buildFrame(
        label: string,
        coordinates: TCoordinates[]
    ): Record<string, TFrame[]> {
        const frames: TFrame[] = [];
        for (const coord of coordinates) {
            frames.push({
                coordinates: {
                    coords: coord.coords,
                    position: coord.position,
                },
                color: this.current?.includes(coord.position)
                    ? this.path?.find(
                        p => p.position === coord.position
                    )?.color || "green"
                    : this.path?.find(
                        p => p.position === coord.position
                    )?.color || "transparent"
            });
        }
        return {
            [label]: frames 
        };
    };

    buildFrames(size: number) {
        const labels = this.getLabels();
        const coordinates = this.getCoordinates(size);
        const keyframes: Record<string, TFrame[]> = {};
        for (const label of labels) {
            Object.assign(keyframes, this.buildFrame(
                label,
                coordinates
            ));
            this.advance();
        }
        return keyframes;
    };

    generateString() {
        return `
            ${Object.entries(this.keyframes || {}).map(
        ([label, frames]) => {
            return `
                        ${label} {
                            box-shadow: ${frames.map((fr, i) => {
        return `${fr.coordinates.coords} ${fr.color}`;
    })};
                        }
                    `;
        }
    ).join("\n")}
        `;
    };
};


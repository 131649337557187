import React, { FunctionComponent } from "react";
import { StyledLoader } from "./Loader.styles";
import { TLoaderProps } from "./Loader.types";
import { useTheme } from "styled-components";
import {theme as _theme} from "@theme";

export const Loader: FunctionComponent<TLoaderProps> = ({
    variant="rainbow",
    speed="regular",
    loaderSize="xl",
    loaderLength="regular",
    ...props
}) => {
    const theme = useTheme();
    return (
        <StyledLoader 
            aria-label="loader" 
            speed={speed}
            variant={variant}
            loaderSize={loaderSize}
            loaderLength={loaderLength}
            theme={theme || _theme}
            {...props}/>
    );
};

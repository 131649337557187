import { Icon, Thing } from "@common/components";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { TCompetitionBoxProps } from "./CompetitionBox.types";
import { TCompetitionResponse, TFetchCompetitionsData, useFetchCompetitions } from "@competition/hooks";
import { TTab, TabbedBox } from "@common/components";
import { TCompetitionFormProps } from "../CompetitionForm/CompetitionForm.types";
import { CompetitionForm } from "../CompetitionForm/CompetitionForm";

export const CompetitionBox: FunctionComponent<TCompetitionBoxProps> = ({
    competition,
    ...props
}) => {
    const [_competition, setCompetition] = useState<TCompetitionResponse>(competition);
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        _competitionId: competition._id,
        method: "GET",
        trigger: false
    });
    const {
        code,
        body
    } = useFetchCompetitions(competitionData);
    const refresh = useCallback(() => {
        setCompetitionData((prev) => ({
            ...prev,
            trigger: true
        }));
    }, []);
    const tabs: TTab[] = useMemo(() => {
        const _tabs: typeof tabs = [{
            id: 1,
            name: "info",
            Component: (props: TCompetitionFormProps) => (
                <CompetitionForm
                    competition={_competition}
                    refresh={refresh}
                    {...props} />
            ),
            mode: "form",
            Icon: (props) => (
                <Icon size="xs" {...props}>
                    <Thing nature="info" />
                </Icon>
            )
        }, {
            id: 2,
            name: "editions",
            Component: (props) => <h2>{"hello editions"}</h2>,
            mode: "data",
            Icon: (props) => (
                <Icon size="xs" {...props}>
                    <Thing nature="soccer-ball" />
                </Icon>
            )
        }];
        return _tabs;
    }, [_competition, refresh]);
    useEffect(() => {
        if (!code) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setCompetition(body as TCompetitionResponse);
        }
    }, [code, body]);
    return (
        <TabbedBox
            {...props}
            aria-label="competition-box"
            initial={1}
            tabs={tabs} 
        />
    );
};

import { useCallback, useState } from "react";
import { IUseTriState } from "./useTriStateSwitch.types";

export function useTriStateSwitch(): IUseTriState {
    const [state, setState] = useState<boolean>();
    const reset = useCallback((newState?: boolean) => {
        setState(newState);
    }, []);
    const flip = useCallback(() => {
        setState((prev) => (!prev));
    }, []);
    return {
        state,
        reset,
        flip,
    };
};

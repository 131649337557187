import { useEffect, useState } from "react";
import { IFetchFederationAvatars, TFederationAvatarResponse, TFetchFederationAvatarsData } from "./useFetchFederationAvatars.types";
import { TFetchAvatarsData, useFetchAvatars } from "@common/hooks";
import { endpoints } from "@common/endpoints";

export function useFetchFederationAvatars({
    _avatarId,
    trigger,
    data,
    ...props
}: TFetchFederationAvatarsData): IFetchFederationAvatars {
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchAvatarsData>({
        endpointURI: endpoints.federationAvatars.buildUrl({
            _avatarId: _avatarId 
        }),
        auth: true,
        inform: true,
        trigger: false
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetchAvatars<TFederationAvatarResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setFired(false);
            if (!data?.file) return;
            const formData = new FormData();
            formData.append("avatar", data.file, data.file.name);
            if (data._federationId && !_avatarId) formData.append(
                "_federationId", 
                data._federationId
            );
            setHookData((prev) => ({
                ...prev,
                endpointURI: endpoints.federationAvatars.buildUrl({
                    _avatarId: _avatarId 
                }),
                method: data.avatar
                    ? "PATCH"
                    : "POST",
                _avatarId: data.avatar
                    ? data.avatar._id || data.avatar.id
                    : undefined,
                data: formData,
                trigger: true
            }));
        }
        return () => {
            if (fired) setHookData((prev) => ({
                ...prev,
                trigger: false
            }));
        };
    }, [
        fired,
        data,
        _avatarId
    ]);
    return {
        response,
        body,
        code,
        loading,
        error,
        fetch
    };
};

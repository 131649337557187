import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const Tournaments = lazy(() => import("@games-api/pages").then(({
    Tournaments
}) => ({default: Tournaments})));
const OneTournament = lazy(() => import("@games-api/pages").then(({
    OneTournament
}) => ({default: OneTournament})));
const OneEdition = lazy(() => import("@games-api/pages").then(({
    OneEdition
}) => ({default: OneEdition})));
const OneGame = lazy(() => import("@games-api/pages").then(({
    OneGame
}) => ({default: OneGame})));


export type TGamesApiRoutes = 
    "tournaments" |
    "oneTournament" |
    "oneEdition" | 
    "oneGame";

export const gamesApiRoutes: Record<TGamesApiRoutes, ILayoutRoute> = {
    tournaments: {
        _id: 1,
        patterns: ["/tournaments"],
        url: "/games-api/tournaments",
        element: Tournaments,
        protect: true,
        urlBuilder: () => "/games-api/tournaments"
    },
    oneTournament: {
        _id: 2,
        patterns: ["/tournaments/:_tournamentId"],
        url: "/games-api/tournaments/:_tournamentId",
        element: OneTournament,
        protect: true,
        urlBuilder: (params) => `/games-api/tournaments${params?._tournamentId
            ? `/${params._tournamentId}`
            : ""}`
    },
    oneEdition: {
        _id: 3,
        patterns: ["/editions/:_editionId"],
        url: "/games-api/editions/:_editionId",
        element: OneEdition,
        protect: true,
        urlBuilder: (params) => `/games-api/editions${params?._editionId
            ? `/${params._editionId}`
            : ""}`
    },
    oneGame: {
        _id: 4,
        patterns: ["/games/:_gameId"],
        url: "/games-api/games/:_gameId",
        element: OneGame,
        protect: true,
        urlBuilder: (params) => `/games-api/games${params?._gameId
            ? `/${params._gameId}`
            : ""}`,
    }
};

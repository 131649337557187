import { Box, Text } from "@common/components";
import { ITableHeader, ITableRow, Table } from "@common/components/Table";
import React, { FunctionComponent, useMemo } from "react";
import { TGameStandingsTableProps } from "./GameStandingsTable.types";
import { parseToOrdinal } from "@utils/parsers";

export const GameStandingsTable: FunctionComponent<TGameStandingsTableProps> = ({
    standings,
    ...props
}) => {
    const standingsHeaders: ITableHeader[] = useMemo(() => {
        const _headers: typeof standingsHeaders = [{
            title: "pos",
            width: "15%",
            alignment: "left"
        }, {
            title: "handle",
            width: "40%",
            alignment: "left"
        }, {
            title: "score",
            width: "30%"
        }, {
            title: "index",
            width: "15%"
        }];
        return _headers;
    }, []);
    const standingsRows: ITableRow[] = useMemo(() => {
        if (!standings) return [];
        const _rows: typeof standingsRows = standings.map((standing) => ({
            values: [
                <Text 
                    key={standing._ticketId}
                    text={standing.position
                        ? parseToOrdinal(standing.position) 
                        : "-"}
                    size="sm" />,
                <Text   
                    key={standing._ticketId}
                    text={`@${standing.handle}`}
                    size="sm" />,
                <Text
                    key={standing._ticketId}
                    text={standing.score.toString()}
                    size="sm"
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }} />,
                <Text
                    key={standing._ticketId}
                    text={standing.scoreIndex.toString()}
                    size="sm"
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }} />
            ]
        }));
        return _rows;
    }, [standings]);
    return (
        <Box
            full
            direction="column"
            style={{
                marginTop: "2rem",
                padding: 0,
                alignItems: "center"
            }}>
            <Text
                size="md"
                caps
                text="standings"
                variant="special" />
            <Table
                tableName="standings table"
                rows={standingsRows || []}
                headers={standingsHeaders || []}
                style={{
                    marginTop: "1rem"
                }} />
        </Box>
    );
};

import { Container, ItemBox, Text, Title } from "@common/components";
import { TPageProps } from "@common/types";
import { TeamForm } from "@team/components";
import React, { FunctionComponent } from "react";

export const NewTeam: FunctionComponent<TPageProps> = ({
    ...props
}) => {
    return (
        <Container
            style={{
                alignItems: "center"
            }}>
            <Text
                component="p"
                size="lg"
                variant="special"
                text="new"
                style={{
                    marginBottom: "-1.5rem"
                }} />
            <Title
                size="xxl"
                text="Team" />
            <ItemBox
                style={{
                    margin: "2rem 0"
                }}>
                <TeamForm />
            </ItemBox>
        </Container>
    );
};

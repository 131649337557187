import { iconSizes } from "./Svg.types";
import styled from "styled-components";
import { TIconProps } from "./Svg.types";

export const Svg = styled.svg<Partial<TIconProps>>`
    stroke-width: ${props => props.size
        ? ["xs", "sm"].includes(props.size)
            ? "2px"
            : ["md", "lg"].includes(props.size)
                ? "3px"
                :  ["xl", "xxl"].includes(props.size)
                    ? "4px"
                    : "6px"
        : "2px"};
    width: ${props => props.size
        ? `${iconSizes[props.size]}`
        : `${iconSizes['xs']}`
};
    height: ${props => props.size
        ? `${iconSizes[props.size]}`
        : `${iconSizes['xs']}`
};
    min-width: ${props => props.size
        ? `${iconSizes[props.size]}`
        : `${iconSizes['xs']}`
};
    min-height: ${props => props.size
        ? `${iconSizes[props.size]}`
        : `${iconSizes['xs']}`
};
    ${props => props.onClick
        && "cursor: pointer;"}
`;
import { Dispatch, useContext } from "react";
import { AppDispatchContext, AppStateContext } from "./AppContext";
import { IAppState, TAppAction } from "./AppContext.types";

export function useAppDispatch(): Dispatch<TAppAction> {
    const context = useContext(AppDispatchContext);
    if (context === undefined) throw new Error("useAppDispatch outta bounds.");
    return context;
}


export function useAppState(): IAppState {
    const context = useContext(AppStateContext);
    if (context === undefined) throw new Error("useAppState outta bounds.");
    return context;
}

import { ComponentPropsWithoutRef } from "react";
import { TFontVariant } from "theme/fonts";
import { TThingProps } from "@common/components";

export interface IResponseNote  {
    hash?: string;
    code: number;
    message: string; 
};

export type TResponseNoteProps = ComponentPropsWithoutRef<"div"> &
    IResponseNote;

export enum NoteNaturesEnum {
    FAILURE = "SORRY, OUR BAD",
    ERROR = "OH, YOUR BAD",
    SUCCESS = "AWESOME!",
    INFO = "ATTENTION"
};

export type TNoteNature = {
    text: NoteNaturesEnum,
    variant: TFontVariant,
    iconNature: TThingProps["nature"];
};

export const noteNatures: Record<string, TNoteNature> = {
    1: {
        text: NoteNaturesEnum.INFO,
        variant: "info",
        iconNature: "success"
    }, 
    2: {
        text: NoteNaturesEnum.SUCCESS,
        variant: "active",
        iconNature: "success"
    }, 
    3: {
        text: NoteNaturesEnum.INFO,
        variant: "info",
        iconNature: "success"
    }, 
    4: {
        text: NoteNaturesEnum.ERROR,
        variant: "error",
        iconNature: "close"
    }, 
    5: {
        text: NoteNaturesEnum.FAILURE,
        variant: "error",
        iconNature: "close"
    }
};


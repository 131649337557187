import React, { FunctionComponent } from "react";
import { TGameMatchFilterPanelProps } from "./GameMatchFilterPanel.types";
import { Box, Button } from "@common/components";

export const GameMatchFilterPanel: FunctionComponent<TGameMatchFilterPanelProps> = ({
    filter,
    setFilter,
    ...props
}) => {
    return (
        <Box
            full
            direction="row"
            style={{justifyContent: "center"}}>
            {["date", "stage", "matchday"].map((_filter, index) => (
                <Button
                    key={index}
                    size="xs"
                    caps
                    label={_filter}
                    nature={filter === _filter ? "accept" : "disabled"}
                    pushed={filter === _filter}
                    onClick={(e) => setFilter(_filter as typeof filter)} 
                    style={{
                        margin: "0 -1px"
                    }}/>
            ))}
        </Box>
    );
};

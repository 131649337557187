import React, { FunctionComponent } from "react";
import { Box, Text, Icon, Arrow } from "@common/components";
import { THorizontalSliderProps } from "./HorizontalSlider.types";

export const HorizontalSlider: FunctionComponent<THorizontalSliderProps> = ({
    current,
    onNext,
    onPrev,
    layer=1,
    ...props
}) => {
    return (
        <Box
            aria-label="selector"
            direction="row"
            loose
            role="slider"
            style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem 1rem",
                textAlign: "center"
            }}
        >
            <Icon 
                role="navigation"
                size={layer === 1 ? "md" : "sm"}
                onClick={onPrev}>
                <Arrow nature={layer === 1 ? "top-left" : "sub-left"} />
            </Icon>
            <Text
                caps
                aria-label={layer === 1
                    ? `top-category: ${current}`
                    : `sub-category: ${current}`
                }
                variant={layer === 1
                    ? "title"
                    : "special"}
                size={layer === 1
                    ? "lg"
                    : "md"}
                text={current} />
            <Icon 
                role="navigation"
                size={layer === 1 ? "md" : "sm"}
                onClick={onNext}>
                <Arrow nature={layer === 1 ? "top-right" : "sub-right"} />
            </Icon>
        </Box>
    );
};


import { Snake } from "@common/animations/Snake";
import styled, { keyframes } from "styled-components";
import { loaderLengths, loaderSizes, loaderVariants, TStyledLoaderProps } from "./Loader.types";


const loadingAnimation = (props: TStyledLoaderProps) => {
    const snake = new Snake(
        props.pattern,
        loaderSizes[props.loaderSize || "xs"],
        loaderVariants[props.variant || "standard"][props.theme.mode],
        loaderLengths[props.loaderLength || "regular"]
    );
    const stringFrames = snake.generateString();
    return keyframes`${stringFrames}`;
};


export const StyledLoader = styled.span<TStyledLoaderProps>`
    height: ${props => loaderSizes[props.loaderSize || "xs"]}px;
    width: ${props => loaderSizes[props.loaderSize || "xs"]}px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${props => loadingAnimation(props)};
    animation-duration: ${props => props.speed === "regular"
        ? "1s"
        : props.speed === "fast"
            ? "700ms"
            : "2.2s"};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: box-shadow 1s linear;
`;
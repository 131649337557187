import React, { FunctionComponent } from "react";
import { StyledBox } from "./Box.styles";
import { TBoxProps } from "./Box.types";

export const Box: FunctionComponent<TBoxProps> = ({
    children,
    ...props
}) => {
    return (
        <StyledBox data-testid="box" {...props}>
            {children}
        </StyledBox>
    );
};

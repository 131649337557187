export type TColorLabel = "black" | "white" | "lightGray" | "midGray" | "fullBlack"
    | "blue"
    | "purple" | "darkPurple" | "midPurple"
    | "pink"
    | "red"
    | "brown" | "lightBrown" | "opaqueBrown"
    | "green" | "darkGreen" | "midGreen"
    | "black30" | "black40" | "black60"
    | "transparent" | "white30";

export type TColors = Record<TColorLabel, string>;

export const colors: TColors = {
    black: "#222", white: "#fff", lightGray: "#ddd", midGray: "#a1a1a1", fullBlack: "#000",
    blue: "#1d8bf0",
    purple: "#4b4e6d", darkPurple: "#32354f", midPurple: "#3d4060",
    pink: "#e75a7c",
    red: "#df4c4c",
    brown: "#c16200", lightBrown: "#decbb7", opaqueBrown: "#a6988a",
    green: "#e6ff0b", midGreen: "#bbd000", darkGreen: "#8d9c0e",
    black30: "#2222224d", black40: "#22222266", black60: "#22222299",
    transparent: "transparent", white30: "#ffffff4d"
};

import { RouteMapper } from "@common/components";
import { TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";
import { Routes } from "react-router";
import { TCompetitionRoutes, competitionRoutes } from "./CompetitionLayout.types";

export const CompetitionLayout: FunctionComponent<TLayoutProps> = (props) => {
    return (
        <Routes>
            {RouteMapper<TCompetitionRoutes>({
                routes: competitionRoutes
            })}
        </Routes>
    );
};

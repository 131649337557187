import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ILogInFormInputs, TLogInFormProps } from "./LogInForm.types";
import { Avatar, Box, Form, IField, IFormButton } from "@common/components";
import { TFetchLoginsData, useFetchLogins } from "@auth/hooks";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { federationRoutes } from "@federation/routes";

export const LogInForm: FunctionComponent<TLogInFormProps> = ({
    ...props
}) => {
    const [hookData, setHookData] = useState<TFetchLoginsData>({
        trigger: false,
        method: "POST"
    });
    const navigate = useNavigate();
    const {
        loading,
        code
    } = useFetchLogins(hookData);
    const fields: IField<ILogInFormInputs>[] = useMemo(() => {
        return [{
            name: "email",
            row: 1,
            validations: {
                required: "email is required 😐"
            },
            defaultValue: "",
            mode: "input",
            modeProps: {
                type: "email",
                autoComplete: "email",
                placeholder: "your_email@mailhost.com"
            }
        }, {
            name: "password",
            row: 2,
            validations: {
                required: "password is required 😐",
                validate: (value: string) => value.length > 7 ? true : false
            },
            defaultValue: "",
            mode: "input",
            modeProps: {
                type: "password",
                autoComplete: "current-password",
                placeholder: "y0urP455w0rd"
            }
        }];
    }, []);
    const buttons: IFormButton[] = useMemo(() => {
        return [{
            row: 1,
            label: "enter",
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            }
        }];
    }, [loading]);
    const onSubmit: SubmitHandler<ILogInFormInputs> = useCallback(
        async(payload) => {
            setHookData((prev) => ({
                ...prev,
                data: payload,
                auth: true,
                trigger: true
            }));
        }, []);
    useEffect(() => {
        if (code) {
            setHookData((prev) => ({
                ...prev,
                trigger: false
            }));
            if (code === 201) {
                navigate(federationRoutes.federations.url);
            }
        }
    }, [code, navigate]);
    return (
        <React.Fragment>
            <Box
                full
                style={{
                    justifyContent: "center",
                    padding: "2rem"
                }}>
                <Avatar size="xxl" avatar={undefined} />
            </Box>
            <Form
                aria-label="log in form"
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit} />
        </React.Fragment>
    );
};
import { ILayoutRoute } from "@common/types";
import React from "react";
import { Route } from "react-router";
// import { AuthRouteWrapper } from "../AuthRouteWrapper";
import { TRouteMapperProps } from "./RouteMapper.types";
import { AuthRouteWrapper } from "../AuthRouteWrapper";

export function RouteMapper<RouteNames extends string>({
    routes,
    ...props
}: TRouteMapperProps<RouteNames>): React.ReactElement {
    return (
        <React.Fragment>
            {Object.entries<ILayoutRoute>(routes).map(
                ([routeName, route]) => (
                    <React.Fragment key={`${route._id}-${routeName}`}>
                        {route.patterns.map((pattern, index) => (
                            <React.Fragment key={`${route._id}-${index}`}>
                                {route.layout ? (
                                    <Route
                                        id={`${route._id}-${index}`}
                                        path={pattern}
                                        element={<route.element />} />
                                ): (
                                    <Route
                                        id={`${route._id}-${index}`}
                                        path={pattern}
                                        element={
                                            <AuthRouteWrapper {...route} />
                                        }>
                                        <Route
                                            id={`${route._id}-${index}-sub`}
                                            path={""}
                                            element={<route.element />} />
                                    </Route>
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )
            )}
        </React.Fragment>
    );
}

import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Container, HorizontalSlider, Title } from "@common/components";
import { useEndlessSlider } from "@common/hooks";
import { TPageProps } from "@common/types";
import { TCompetitionResponse, TFetchCompetitionsData, useFetchCompetitions } from "@competition/hooks";
import { TEditionResponse, TFetchEditionsData, useFetchEditions } from "@edition/hooks";
import { matchRoutes } from "@match/routes/MatchLayout.types";
import { useNavigate } from "react-router";
import { FilteredMatches, MatchFilterPanel, TFilterType } from "@match/components";
// import { useAppState } from "@app/context";

export const Matches: FunctionComponent<TPageProps> = (props) => {
    const navigate = useNavigate();
    // useAppState();
    const [filter, setFilter] = useState<TFilterType>("status");
    const [competitions, setCompetitions] = useState<TCompetitionResponse[]>();
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        method: "GET",
        trigger: true
    });
    const {
        code: competitionCode,
        body: competitionBody
    } = useFetchCompetitions(competitionData);
    const {
        current: competition,
        next: nextCompetition,
        prev: previousCompetition
    } = useEndlessSlider<TCompetitionResponse>(
        competitions
    );
    const [editions, setEditions] = useState<TEditionResponse[]>();
    const [editionData, setEditionData] = useState<TFetchEditionsData>({
        trigger: false,
        method: "GET"
    });
    const {
        code: editionCode,
        body: editionBody
    } = useFetchEditions(editionData);
    useEffect(() => {
        if (!competitionCode) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (competitionCode === 200) {
            setCompetitions(competitionBody as TCompetitionResponse[]);
        }
    }, [competitionCode, competitionBody]);
    const {
        current: edition,
        next: nextEdition,
        prev: previousEdition
    } = useEndlessSlider<TEditionResponse>(
        editions
    );
    useEffect(() => {
        if (competition) {
            setEditionData((prev) => ({
                ...prev,
                qs: `_competitionId=${competition.id || competition._id}`,
                trigger: true
            }));
        }
    }, [competition]);
    useEffect(() => {
        if (!editionCode) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (editionCode === 200) {
            setEditions(editionBody as TEditionResponse[]);
        }
    }, [editionBody, editionCode]);
    return (
        <Container
            style={{
                alignItems: "center"
            }}>
            <Title
                text="Matches"
                size="xxl" />
            <HorizontalSlider
                current={competition?.name || "loading..."}
                onNext={nextCompetition}
                onPrev={previousCompetition} />
            <HorizontalSlider
                layer={2}
                current={edition?.name || ""}
                onNext={nextEdition}
                onPrev={previousEdition} />
            <Box
                full
                direction="row"
                style={{justifyContent: "center"}}>
                <Button
                    caps
                    label="new match"
                    nature="accept"
                    onClick={e => navigate(matchRoutes.newMatch.url)} />
            </Box>
            <MatchFilterPanel
                filter={filter}
                setFilter={setFilter} />
            {/* <Box
                full
                direction="row"
                style={{justifyContent: "center"}}>
                {["status", "date", "stage", "matchday"].map((_filter, index) => (
                    <Button
                        key={index}
                        size="xs"
                        caps
                        label={_filter}
                        nature={filter === _filter ? "accept" : "disabled"}
                        pushed={filter === _filter}
                        onClick={(e) => setFilter(_filter as TFilterType)} 
                        style={{
                            margin: "0 -1px"
                        }}/>
                ))}
            </Box> */}
            <FilteredMatches
                filter={filter}
                edition={edition} />
        </Container>
    );
};
import { endpointsURI } from "./endpoint.types";
import { TEndpoint } from "@common/types";

export const apiURL: string = process.env.API_URL || 'http://localhost:5000/api';
export const gamesApiURL: string = process.env.GAMES_API_URL || 'http://localhost:5001/api';

export const endpoints: Record<
    keyof typeof endpointsURI,
    TEndpoint
> = {
    users: {
        url: `${apiURL}/${endpointsURI.users}`,
        buildUrl({
            _userId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.users}`;
            if (_userId) str += `/${_userId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    whoAmI: {
        url: `${apiURL}/${endpointsURI.whoAmI}`,
        buildUrl() {
            return `${apiURL}/${endpointsURI.whoAmI}`;
        }
    },
    logins: {
        url: `${apiURL}/${endpointsURI.logins}`,
        buildUrl() {
            return `${apiURL}/${endpointsURI.logins}`;
        }
    },
    federations: {
        url: `${apiURL}/${endpointsURI.federations}`,
        buildUrl({
            _federationId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.federations}`;
            if (_federationId) str += `/${_federationId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    countries: {
        url: `${apiURL}/${endpointsURI.countries}`,
        buildUrl() {
            return `${apiURL}/${endpointsURI.countries}`;
        }
    },
    federationAvatars: {
        url: `${apiURL}/${endpointsURI.federationAvatars}`,
        buildUrl({
            _avatarId
        }) {
            let str = `${apiURL}/${endpointsURI.federationAvatars}`;
            if (_avatarId) str += `/${_avatarId}`;
            return str;
        }
    },
    teams: {
        url: `${apiURL}/${endpointsURI.teams}`,
        buildUrl({
            _teamId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.teams}`;
            if (_teamId) str += `/${_teamId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    teamAvatars: {
        url: `${apiURL}/${endpointsURI.teamAvatars}`,
        buildUrl({
            _avatarId
        }) {
            let str = `${apiURL}/${endpointsURI.teamAvatars}`;
            if (_avatarId) str += `/${_avatarId}`;
            return str;
        }
    },
    competitions: {
        url: `${apiURL}/${endpointsURI.competitions}`,
        buildUrl({
            _competitionId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.competitions}`;
            if (_competitionId) str += `/${_competitionId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    competitionAvatars: {
        url: `${apiURL}/${endpointsURI.competitionAvatars}`,
        buildUrl({
            _avatarId
        }) {
            let str = `${apiURL}/${endpointsURI.competitionAvatars}`;
            if (_avatarId) str += `/${_avatarId}`;
            return str;
        }
    },
    editions: {
        url: `${apiURL}/${endpointsURI.editions}`,
        buildUrl({
            _editionId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.editions}`;
            if (_editionId) str += `/${_editionId}`;
            if (qs) str+= `?${qs}`;
            return str;
        }
    },
    matches: {
        url: `${apiURL}/${endpointsURI.matches}`,
        buildUrl({
            _matchId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.matches}`;
            if (_matchId) str += `/${_matchId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    getTournaments: {
        url: `${gamesApiURL}/${endpointsURI.getTournaments}`,
        buildUrl({
            _tournamentId,
            qs
        }) {
            let str = `${gamesApiURL}/${endpointsURI.getTournaments}`;
            if (_tournamentId) str += `/${_tournamentId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    tournaments: {
        url: `${apiURL}/${endpointsURI.tournaments}`,
        buildUrl({
            _tournamentId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.tournaments}`;
            if (_tournamentId) str += `/${_tournamentId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    getGamesApiEditions: {
        url: `${gamesApiURL}/${endpointsURI.getGamesApiEditions}`,
        buildUrl({
            _editionId,
            qs
        }) {
            let str = `${gamesApiURL}/${endpointsURI.getGamesApiEditions}`;
            if (_editionId) str += `/${_editionId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    gamesApiEditions: {
        url: `${apiURL}/${endpointsURI.gamesApiEditions}`,
        buildUrl({
            _editionId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.gamesApiEditions}`;
            if (_editionId) str += `/${_editionId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    getGamesApiGames: {
        url: `${gamesApiURL}/${endpointsURI.getGamesApiGames}`,
        buildUrl({
            _gameId,
            qs,
            standings,
        }) {
            let str = `${gamesApiURL}/${endpointsURI.getGamesApiGames}`;
            if (_gameId) str += `/${_gameId}`;
            if (standings) str += `/standings`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    gamesApiGames: {
        url: `${apiURL}/${endpointsURI.gamesApiGames}`,
        buildUrl({
            _gameId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.gamesApiGames}`;
            if (_gameId) str += `/${_gameId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    getGamesApiGameMatches: {
        url: `${gamesApiURL}/${endpointsURI.getGamesApiGameMatches}`,
        buildUrl({
            _gameMatchId,
            qs
        }) {
            let str = `${gamesApiURL}/${endpointsURI.getGamesApiGameMatches}`;
            if (_gameMatchId) str += `/${_gameMatchId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    gamesApiGameMatches: {
        url: `${apiURL}/${endpointsURI.gamesApiGameMatches}`,
        buildUrl({
            _gameMatchId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.gamesApiGameMatches}`;
            if (_gameMatchId) str += `/${_gameMatchId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    getGamesApiPrizes: {
        url: `${gamesApiURL}/${endpointsURI.getGamesApiPrizes}`,
        buildUrl({
            _prizeId,
            qs
        }) {
            let str = `${gamesApiURL}/${endpointsURI.getGamesApiPrizes}`;
            if (_prizeId) str += `/${_prizeId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    gamesApiPrizes: {
        url: `${apiURL}/${endpointsURI.gamesApiPrizes}`,
        buildUrl({
            _prizeId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.gamesApiPrizes}`;
            if (_prizeId) str += `/${_prizeId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    },
    consortiumMemberships: {
        url: `${apiURL}/${endpointsURI.consortiumMemberships}`,
        buildUrl({
            _consortiumMembershipId,
            qs
        }) {
            let str = `${apiURL}/${endpointsURI.consortiumMemberships}`;
            if (_consortiumMembershipId) str += `/${_consortiumMembershipId}`;
            if (qs) str += `?${qs}`;
            return str;
        }
    }
};

import { Avatar, Box, Button, ItemBox, Text } from "@common/components";
import React, { FunctionComponent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { MatchForm } from "../MatchForm";
import { TMatchBoxProps } from "./MatchBox.types";
import { MatchStatusesEnum } from "@common/enums";
import { getTimeTo, translateDate } from "@utils/helpers";
import { TFetchMatchesData, TMatchResponse, useFetchMatches } from "@match/hooks";
import { MatchScoreForm } from "../MatchScoreForm";

export const MatchBox: FunctionComponent<TMatchBoxProps> = ({
    match,
    edition,
    ...props
}) => {
    const [_match, setMatch] = useState<TMatchResponse>(match);
    const [matchData, setMatchData] = useState<TFetchMatchesData>({
        method: "GET",
        _matchId: match._id,
        trigger: false
    });
    const {
        code: matchCode,
        body: matchBody
    } = useFetchMatches(matchData);
    const refresh = useCallback(() => {
        setMatchData((prev) => ({
            ...prev,
            method: "GET",
            trigger: true
        }));
    }, []);
    const isImminent: boolean = useMemo(() => {
        if (_match.status !== MatchStatusesEnum.PLANNED) return false;
        if (
            new Date().getTime() <=
            translateDate({
                ms: - 1000 * 60 * 10,
                start: new Date(_match.startsAt)
            }).getTime()
        ) return false;
        return true;
    }, [_match]);
    const updateStatus: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
        const _status: MatchStatusesEnum = _match.status === MatchStatusesEnum.PLANNED
            ? MatchStatusesEnum.PLAYING
            : _match.status === MatchStatusesEnum.PLAYING
                ? MatchStatusesEnum.FINISHED
                : MatchStatusesEnum.CONFIRMED;
        setMatchData((prev) => ({
            ...prev,
            method: "PATCH",
            data: {
                status: _status
            },
            trigger: true
        }));
    }, [_match.status]);
    useEffect(() => {
        if (!matchCode) return;
        setMatchData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (matchCode === 200) {
            setMatch(matchBody as TMatchResponse);
        }
    }, [matchCode, matchBody]);
    return (
        <ItemBox
            stylesFrom={{
                xs: {
                    margin: "2rem 0"
                },
                lg: {
                    margin: "2rem 1rem"
                }
            }}
            aria-label="match-box"
            {...props}>
            {edition && (
                <Box
                    loose
                    direction="row">
                    <Avatar
                        size="lg"
                        avatar={{
                            avatar: edition.competition?.avatar,
                            file: undefined
                        }}
                        style={{
                            margin: "0 1rem 0 0"
                        }} />
                    <Box
                        direction="column"
                        style={{padding: 0}}>
                        <Text
                            component="h3"
                            size="md"
                            text={edition.competition?.name || ""} />
                        <Text
                            component="h4"
                            size="sm"
                            text={edition.competition?.description || ""} />
                    </Box>
                </Box>
            )}
            {_match.status === MatchStatusesEnum.PLANNED && !isImminent ? (
                <MatchForm
                    refresh={refresh}
                    match={_match}
                    edition={edition}/>    
            ) : (
                <Box
                    direction="column">
                    <Text   
                        variant="special"
                        component="h5"
                        size="sm"
                        text={edition?.name || ""} />
                    <Text
                        caps
                        component="p"
                        size="sm"
                        text={_match.stage} />
                    <Text
                        caps
                        component="p"
                        size="sm"
                        text={_match.matchday} />
                    <Text
                        caps
                        component="p"
                        size="sm"
                        text={_match.name} />
                    <Text
                        caps
                        component="p"
                        size="sm"
                        text={new Date(_match.startsAt).toLocaleString()} />
                </Box>
            )}
            {isImminent && (
                <Box
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        margin: 0,
                        justifyContent: "center"
                    }}>
                    <Text
                        caps
                        size="sm"
                        variant="error"
                        component="h4"
                        text={`kicks off in ${getTimeTo(_match.startsAt)}!`} />
                </Box>
            )}
            <MatchScoreForm
                match={_match}
                refresh={refresh} />   
            {(
                [
                    MatchStatusesEnum.PLAYING,
                    MatchStatusesEnum.FINISHED
                ].includes(_match.status) ||
                isImminent
            ) && (
                <React.Fragment>
                    <Box
                        full
                        direction="row"
                        style={{
                            justifyContent: "center",
                            paddingTop: 0
                        }}>
                        <Button 
                            caps
                            label={isImminent 
                                ? "START"
                                : _match.status === MatchStatusesEnum.PLAYING
                                    ? "FINISH"
                                    : "CONFIRM"
                            }
                            onClick={updateStatus}
                            nature="accept"
                            style={{
                                marginTop: 0
                            }} />
                    </Box>
                </React.Fragment>
            )}
        </ItemBox>
    );
};

import styled from "styled-components";
import { TAvatarProps } from "./Avatar.types";
import { iconSizes } from "../Icon/Svg.types";

export const StyledAvatar = styled("div")<TAvatarProps>`
    width: ${props => iconSizes[props.size || "md"]};
    height: ${props => iconSizes[props.size || "md"]};
    min-width: ${props => iconSizes[props.size || "md"]};
    min-height: ${props => iconSizes[props.size || "md"]};
    display: flex;
    align-self: center;
    justify-self: center;
    ${props => {
        if (props.avatar?.file) return `background-image: url(${URL.createObjectURL(props.avatar.file)});`;
        if (props.avatar?.avatar?.secureUrl) return `background-image: url(${props.avatar.avatar.secureUrl});`;
        return `background-image: linear-gradient(to bottom right, #d4d4d4, #9a9a9a);`;
        
    }}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: ${props => props.edit
        ? "pointer"
        : "default"
};
    border-radius: ${props => props.squared 
        ? (!props.avatar?.file && !props.avatar?.avatar)
            ? "50%"
            : "0%" 
        : "50%"};
    overflow: visible;
    position: relative
`;
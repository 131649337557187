import { useTriStateSwitch } from "@common/hooks";
import React, { FunctionComponent, useEffect } from "react";
import { ContextMenu } from "../ContextMenu/ContextMenu";
import { StyledUserMenu } from "./UserMenu.styles";
import { TUserMenuProps } from "./UserMenu.types";
import { useAppState } from "@app/context";
import { Avatar } from "@common/components";

export const UserMenu: FunctionComponent<TUserMenuProps> = ({
    content,
    ...props
}) => {
    const appState = useAppState();
    const {state, flip} = useTriStateSwitch();
    const menuRef = React.createRef<HTMLUListElement>();
    const isoRef = React.createRef<HTMLDivElement>();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current?.contains(event.target as Node)) return;
            if (isoRef.current?.contains(event.target as Node)) return;
            flip();
        };
        if (state) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [state, flip, isoRef, menuRef]);
    return (
        <StyledUserMenu aria-label="user-menu">
            <Avatar
                avatar={{
                    avatar: appState.user?.avatar,
                    file: undefined
                }}
                ref={isoRef}
                size="md"
                aria-label="user-icon"
                onClick={e => flip()}
                style={{
                    cursor: "pointer"
                }} />
            {state && (<ContextMenu 
                ref={menuRef} 
                content={content} 
                flip={flip} />)}
        </StyledUserMenu>
    );
};

import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const Federations = lazy(() => import("@federation/pages").then(({
    Federations
}) => ({default: Federations})));
const NewFederation = lazy(() => import("@federation/pages").then(({
    NewFederation
}) => ({default: NewFederation})));

export type TFederationRoutes = "federations"
    | "newFederation"; // | "oneFederation";

export const federationRoutes: Record<TFederationRoutes, ILayoutRoute> = {
    federations: {
        _id: 1,
        patterns: [""],
        url: "/federations",
        element: Federations,
        protect: true,
        urlBuilder: () => "/federations"
    },
    newFederation: {
        _id: 2,
        patterns: ["/new"],
        url: "/federations/new",
        element: NewFederation,
        protect: true,
        urlBuilder: () => "/federations/new"
    }
};

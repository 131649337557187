import { useEffect, useState } from "react";
import { IFetchConsortiumMemberships, TConsortiumMembershipBody, TFetchConsortiumMembershipsData } from "./useFetchConsortiumMemberships.types";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { TConsortiumMembershipResponse } from "@federation/types/Federation.types";

export function useFetchConsortiumMemberships({
    _consortiumMembershipId,
    method,
    data,
    trigger,
    successMessage=`membership successfuly ${method === "POST" 
        ? "created" 
        : "updated"} 👔`,
    inform=true,
    options,
    ignoreHeaders,
    ...props
}: TFetchConsortiumMembershipsData): IFetchConsortiumMemberships {
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchData<TConsortiumMembershipBody>>({
        endpointURI: _consortiumMembershipId
            ? endpoints.consortiumMemberships.buildUrl({
                _consortiumMembershipId: _consortiumMembershipId
            })
            : endpoints.consortiumMemberships.url,
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage,
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TConsortiumMembershipBody, TConsortiumMembershipResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _consortiumMembershipId
                    ? endpoints.consortiumMemberships.buildUrl({
                        _consortiumMembershipId: _consortiumMembershipId
                    })
                    : endpoints.consortiumMemberships.url,
                method: method,
                auth: method !== "GET",
                successMessage: successMessage,
                options: options,
                inform: inform,
                data: data,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _consortiumMembershipId,
        method,
        successMessage,
        options,
        inform,
        data
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};

import React, { FunctionComponent } from "react";
import { TMatchFilterPanelProps } from "./MatchFilterPanel.types";
import { Box, Button } from "@common/components";
import { TFilterType } from "../FilteredMatches";

export const MatchFilterPanel: FunctionComponent<TMatchFilterPanelProps> = ({
    filter,
    setFilter,
    ...props
}) => {
    return (
        <Box
            full
            direction="row"
            style={{justifyContent: "center"}}>
            {["status", "date", "stage", "matchday"].map((_filter, index) => (
                <Button
                    key={index}
                    size="xs"
                    caps
                    label={_filter}
                    nature={filter === _filter ? "accept" : "disabled"}
                    pushed={filter === _filter}
                    onClick={(e) => setFilter(_filter as TFilterType)} 
                    style={{
                        margin: "0 -1px"
                    }}/>
            ))}
        </Box>
    );
};

import { Dispatch } from "react";
import { EAppActionType, TAppAction } from "./AppContext.types";
import { TUserResponse } from "@auth/types";
import { TCountryResponse } from "@common/types";
import { TFederationResponse } from "@federation/hooks";

export const toggleTheme = (dispatch: Dispatch<TAppAction>): void => {
    dispatch({
        type: EAppActionType.toggleTheme
    });
};

export const authenticate = (
    user: TUserResponse,
    dispatch: Dispatch<TAppAction>
): void => {
    dispatch({
        type: EAppActionType.authenticate,
        payload: user
    });
};

export const unauthenticate = (
    dispatch: Dispatch<TAppAction>
): void => {
    dispatch({
        type: EAppActionType.unauthenticate
    });
};

export const setCountries = (
    countries: TCountryResponse[],
    dispatch: Dispatch<TAppAction>
): void => {
    dispatch({
        type: EAppActionType.setCountries,
        payload: countries
    });
};

export const setFederations = (
    federations: TFederationResponse[],
    dispatch: Dispatch<TAppAction>
): void => {
    dispatch({
        type: EAppActionType.setFederations,
        payload: federations
    });
};

import React, { FunctionComponent } from "react";
import { TTableProps } from "./Table.types";
import { colors } from "@theme";
import { useMediaQuery } from "@common/hooks";
import { StyledTable, StyledTableHeader, StyledTableRow } from "./Table.styles";
import { Text } from "../Text";

export const Table: FunctionComponent<TTableProps> = ({
    rows,
    headers,
    firstColor=colors.white,
    secondColor=colors.white30,
    striped=true,
    tableName="standard",
    ...props
}) => {
    const md = useMediaQuery("md");
    return (
        <StyledTable 
            role="table"
            aria-label={`${tableName} table`}
            {...props}>
            {headers && (
                <StyledTableHeader>
                    <tr 
                        role="rowheader"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "row nowrap"
                        }}>
                        {headers?.map((header, index) => {
                            if (header.hideOnSmall && !md) return;
                            return (
                                <th
                                    key={index}
                                    style={{
                                        margin: 0,
                                        display: "flex",
                                        width: md
                                            ? header.widthAfterSmall
                                                ? header.widthAfterSmall
                                                : header.width || "100%"
                                            : header.width || "100%"
                                    }}>
                                    <Text
                                        size="sm"
                                        style={{
                                            textAlign: header.alignment
                                                ? header.alignment
                                                : "center",
                                            width: "100%"
                                        }}
                                        text={header.title || ""}
                                        variant={"special"} />
                                </th>
                            );
                        })}
                    </tr>
                </StyledTableHeader>
            )}
            <tbody>
                {rows.map((row, index) => {
                    return (
                        <StyledTableRow 
                            key={index} 
                            aria-label="table row"
                            role="row"
                            onClick={row.onClick}
                            style={{
                                backgroundColor: striped
                                    ? (index === 0 || index % 2 === 0)
                                        ? firstColor
                                        : secondColor
                                    : firstColor,
                                cursor: row.onClick
                                    ? "pointer"
                                    : "auto"
                            }}>
                            {row.values.map((value, _index) => {
                                if (headers && headers[_index].hideOnSmall && !md) return;
                                return (
                                    <td
                                        key={_index}
                                        style={{
                                            textAlign: headers && headers[_index].alignment
                                                ? headers[_index].alignment
                                                : "center",
                                            display: "flex",
                                            width: md
                                                ? (headers && headers[_index].widthAfterSmall) || (
                                                    headers && headers[_index].width
                                                ) || "100"
                                                : headers && headers[_index].width || "100%"
                                        }}>
                                        {(
                                            typeof value === "string" ||
                                            typeof value === "number"
                                        ) && (
                                            <Text
                                                size="sm"
                                                style={{
                                                    textAlign: headers && headers[_index].alignment
                                                        ? headers[_index].alignment
                                                        : "center",
                                                    width: "100%"
                                                }}
                                                text={value?.toString() || ""} />
                                        )}
                                        {(
                                            typeof value !== "string" &&
                                            typeof value !== "number"
                                        ) && (
                                            <React.Fragment>
                                                {value}
                                            </React.Fragment>
                                        )}
                                    </td>
                                );})}
                        </StyledTableRow>
                    );
                })}
            </tbody>
        </StyledTable>
    );
};

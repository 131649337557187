export function parseTo(
    value: string, 
    output: "kebab" | "snake"
) {
    // assures PascalCase
    const preclean: string = value.trim().split(" ").map((_word) => {
        return `${_word.charAt(0).toUpperCase()}${_word.slice(1)}`;
    }).join("");
    const clean = preclean
        .replace(/[\s-_]/g, "")
        .split(/(?=[A-Z][a-z]+)/);
    const payload: string[] = [];
    for (const word of clean) {
        const _words = word
            .split(/(?<![A-Z])(?=[A-Z])/);
        if (_words.length > 1) {
            payload.push(..._words);
            continue;
        } 
        payload.push(word);
    }
    if (payload.length < 1) throw new Error(
        "invalid input, unable to transform 😐"
    );
    let parsed = (payload.shift() as string)
        .toLowerCase();
    for (const word of payload) {
        parsed += `${output === "kebab" 
            ? "-" 
            : "_"}${word.toLowerCase()}`;
    }
    return parsed;
};

export function asPercentage({
    value,
    hundredBased
}: {
    value: string | number;
    hundredBased?: boolean;
}): string {
    let _value = 0;
    if (typeof value === 'string') _value = parseFloat(value);
    if (typeof value === 'number') _value = value;
    if (!hundredBased) _value = _value * 100;
    return `${_value.toFixed(0)}%`;
};

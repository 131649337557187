import { Button } from "@common/components";
import React, { FunctionComponent, useEffect, useState } from "react";
import { TGameMatchTogglerProps } from "./GameMatchToggler.types";
import { useFetchGamesApiGameMatches } from "@games-api/hooks";
import { TFetchGamesApiGameMatchesData } from "@games-api/hooks/useFetchGamesApitGameMatches";
import { TGamesApiGameMatchResponse } from "@games-api/types/GamesApiGameMatch.types";

export const GameMatchToggler: FunctionComponent<TGameMatchTogglerProps> = ({
    game,
    gameMatch,
    match,
    refresh,
    ...props
}) => { 
    const [_gameMatch, setGameMatch] = useState<TGamesApiGameMatchResponse | undefined>(gameMatch);
    const [gameMatchData, setGameMatchData] = useState<TFetchGamesApiGameMatchesData>({
        method: _gameMatch ? "DELETE" : "POST",
        trigger: false 
    });
    const {
        code: gameMatchCode,
        body: gameMatchBody,
        loading: gameMatchLoading
    } = useFetchGamesApiGameMatches(gameMatchData);
    useEffect(() => {
        if (!gameMatchCode) return;
        setGameMatchData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (gameMatchCode === 201) {
            setGameMatch(gameMatchBody as TGamesApiGameMatchResponse);
        }
        if (gameMatchCode === 204) {
            setGameMatch(undefined);
        }
        refresh && refresh();
    }, [gameMatchCode, gameMatchBody, refresh]);
    return (
        <Button
            label={_gameMatch ? "remove" : "add"}
            nature={_gameMatch ? "decline" : "accept"}
            size="xs"
            onClick={(event) => setGameMatchData((prev) => ({
                ...prev,
                method: _gameMatch ? "DELETE" : "POST",
                _gameMatchId: _gameMatch ? _gameMatch._id : undefined,
                data: _gameMatch ? undefined : {
                    _gameId: game._id,
                    _matchId: match._id
                },
                trigger: true
            }))}
            loading={gameMatchLoading}
            loader={{
                speed: "regular",
                variant: "rainbow",
                loaderSize: "sm",
                loaderLength: "regular"
            }}
            {...props} />
    );
};

import styled from "styled-components";

export const StyledSpanArrow = styled.span`
    ::after {
        content: " ";
        width: 9px;
        height: 2px;
        transform: translate(calc(50% - 3px), -50%) rotate(45deg);
        background-color: ${props => props.theme.palette.textRegular};
        position: absolute;
        top: 50%;
        right: 50%;
    }
    ::before {
        content: " ";
        width: 9px;
        height: 2px;
        background-color: ${props => props.theme.palette.textRegular};
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(calc(50% + 3px), -50%) rotate(-45deg);
    }
    position: absolute;
    bottom: 50%;
    right: 0.8rem;
    margin: 0;
    padding: 0;
    width: 1.5rem;
    height: 1rem;
    background-color: ${props => props.theme.palette.inputBackground};
    transform: translate(0, 50%);
    pointer-events: none;
`;
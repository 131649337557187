import { TPatternName } from "@common/animations/Snake";
import { colors, TColorLabel, ThemeType, ThemeTypeMode } from "@theme";
import { ComponentPropsWithoutRef } from "react";
import { ThemeProps } from "styled-components";

export type TLoaderSize = "xs" | "sm" | "md" | "lg" | "xl";

export type TLoaderVariant = "standard" | "active" | "rainbow" | "white";

export type TLoaderSpeed = "fast" | "regular" | "slow";

export type TLoaderLength = "short" | "regular" | "long" | "full";

export type TLoaderProps = ComponentPropsWithoutRef<"span"> & 
    ILoader;

export type TStyledLoaderProps = TLoaderProps &
    ThemeProps<ThemeType>;

export interface ILoader {
    loaderSize?: TLoaderSize;
    variant?: TLoaderVariant;
    speed?: TLoaderSpeed;
    pattern?: TPatternName;
    height?: "100%" | "100vh" | "auto";
    loaderLength?: TLoaderLength;
}

export const loaderLengths: Record<TLoaderLength, number> = {
    short: 4,
    regular: 8,
    long: 12,
    full: 16,  
};

export const loaderSizes: Record<TLoaderSize, number> = {
    xs: 3,
    sm: 5,
    md: 10,
    lg: 20,
    xl: 30
};

export const loaderSpeeds: Record<TLoaderSpeed, number> = {
    regular: 16,
    slow: 8,
    fast: 24,
};

export const loaderVariants: Record<TLoaderVariant, Record<ThemeTypeMode, TColorLabel[]>> = {
    standard: {
        light: [
            colors.black as TColorLabel,
        ],
        dark: [
            colors.white as TColorLabel,
        ]
    },
    active: {
        light: [
            colors.green as TColorLabel,
        ],
        dark: [
            colors.green as TColorLabel,
        ],
    },
    rainbow: {
        light: [
            colors.darkPurple as TColorLabel,
            colors.purple as TColorLabel,
            colors.blue as TColorLabel,
            colors.green as TColorLabel,
            colors.lightBrown as TColorLabel,
            colors.brown as TColorLabel,
            colors.red as TColorLabel,
            colors.pink as TColorLabel,
        ], 
        dark: [
            colors.darkPurple as TColorLabel,
            colors.purple as TColorLabel,
            colors.blue as TColorLabel,
            colors.green as TColorLabel,
            colors.lightBrown as TColorLabel,
            colors.brown as TColorLabel,
            colors.red as TColorLabel,
            colors.pink as TColorLabel,
        ], 
    },
    white: {
        light: [
            colors.white as TColorLabel,
        ],
        dark: [
            colors.white as TColorLabel,
        ]
    },
};
import { ILayoutRoute } from "@common/types";
import { NewEdition } from "@edition/pages/NewEdition";
import { lazy } from "react";
const Editions = lazy(() => import("@edition/pages").then(({
    Editions
}) => ({
    default: Editions
})));

export type TEditionRoutes = "editions"
    | "newEdition";

export const editionRoutes: Record<TEditionRoutes, ILayoutRoute> = {
    editions: {
        _id: 1,
        patterns: [""],
        url: "/editions",
        element: Editions,
        protect: true,
        urlBuilder: () => "/editions"
    },
    newEdition: {
        _id: 2,
        patterns: ["/new"],
        url: "/editions/new",
        element: NewEdition,
        protect: true,
        urlBuilder: () => "/editions/new"
    }
};

import { Navbar } from "@common/components";
import { TDonkeyBarProps } from "./DonkeyBar.types";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { toggleTheme, useAppDispatch, useAppState } from "@app/context";
import { UserMenu } from "../UserMenu/UserMenu";
import { TContexMenuSection } from "../ContextMenu";
import { useNavigate } from "react-router";
import { appRoutes } from "@app/routes";
import { TFetchLoginsData, useFetchLogins } from "@auth/hooks";
import { federationRoutes } from "@federation/routes";
import { teamRoutes } from "@team/routes";
import { competitionRoutes } from "@competition/routes";
import { editionRoutes } from "@edition/routes/EditionLayout.types";
import { matchRoutes } from "@match/routes/MatchLayout.types";
import { gamesApiRoutes } from "@games-api/routes/GamesApiLayout.types";

export const DonkeyBar: FunctionComponent<TDonkeyBarProps> = ({
    ...props
}) => {
    const appDispatch = useAppDispatch();
    const appState = useAppState();
    const navigate = useNavigate();
    const [hookData, setHookData] = useState<TFetchLoginsData>({
        method: "DELETE",
        trigger: false
    });
    const {
        code
    } = useFetchLogins(hookData);
    const userMenuContent: TContexMenuSection[] = useMemo(() => {
        // const common: TContextMenuItem[] = []; 
        // [
        // {
        //     name: "HOW TO",
        //     action: (event: React.MouseEvent) => navigate(appRoutes.howTo.url)
        // }
        // ];
        if (appState.authenticated) return [{
            name: appState.user?.email as string,
            items: [{
            // ...common, {
                name: "LOG OUT",
                action: (event) => setHookData((prev) => ({
                    ...prev,
                    trigger: true
                }))
            }]
        }, {
            name: "",
            items: [{
            //     name: "PROFILE",
            //     action: (event) => navigate(usersRoutes.profile.url)
            // }, {
                name: "FEDERATIONS",
                action: (event) => navigate(federationRoutes.federations.url)
            }, {
                name: "COMPETITIONS",
                action: (event) => navigate(competitionRoutes.competitions.url)
            }, {
                name: "EDITIONS",
                action: (event) => navigate(editionRoutes.editions.url)
            }, {
                name: "TEAMS",
                action: (event) => navigate(teamRoutes.teams.url)
            }, {
                name: "MATCHES",
                action: (event) => navigate(matchRoutes.matches.url)
            }]
        }, {
            name: "games-api",
            items: [{
                name: "TOURNAMENTS",
                action: (event) => navigate(gamesApiRoutes.tournaments.url)
            }]
        }];
        return [{
            name: "Not signed in..."
        }, {
            name: "",
            items: [{
                name: "LOG IN",
                action: (event) => navigate(appRoutes.home.urlBuilder())
            },
            // ...common
            ]
        }];
    }, [
        appState.authenticated, 
        appState.user, 
        navigate
    ]);
    useEffect(() => {
        if (code) {
            setHookData((prev) => ({
                ...prev, 
                trigger: false
            }));
        }
    }, [code]);
    return (
        <Navbar
            isotype
            role={"navigation"}
            isotypeAction={(e) => toggleTheme(appDispatch)}
            options={[{
                name: `@${appState.user?.email.split("@")[0] || "anon"}`,
                path: "" 
                // appState.user 
                //     ? usersRoutes.profile.url 
                //     : authRoutes.auth.urlBuilder({
                //         resource: 'log-in'
                //     })
            }]}>
            <UserMenu content={userMenuContent} />
        </Navbar>
    );
};
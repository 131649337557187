import React, { ElementRef, FunctionComponent } from "react";
import { Svg } from "./Svg.styles";
import { TIconProps } from "./Svg.types";

React.forwardRef;

export const Icon: FunctionComponent<TIconProps> = React.forwardRef<
    ElementRef<"svg">, TIconProps
>(({
    size="xs",
    nature,
    ...props
}, ref) => {
    return (
        <Svg
            name={nature}
            ref={ref}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            role={"figure"}
            data-testid="isotype"
            shapeRendering="auto"
            viewBox="0 -0.5 512 512"
            size={size}
            {...props} />
            
    );
});

Icon.displayName = "Icon";

import { addResponseNote, useNotesDispatch } from "@app/context";
import { useCallback, useEffect, useState } from "react";
import { IFetch, TFetchData } from "./useFetch.types";

export function useFetch<
    ReqBody extends object, 
    ResBody extends (object | string)
>({
    endpointURI,
    method="GET",
    data,
    auth,
    options={},
    trigger=false,
    successMessage,
    inform=true,
    ignoreHeaders
}: TFetchData<ReqBody>): IFetch<ResBody> {
    const notesDispatch = useNotesDispatch();
    const [loading, setLoading] = useState<boolean>();
    const [response, setResponse] = useState<Response>();
    const [body, setBody] = useState<ResBody>();
    const [error, setError] = useState<Error>();
    const [fired, setFired] = useState<boolean>();
    const _fetch = useCallback(async(
        url?: string,
        moreOptions?: RequestInit
    ): Promise<[Response, ResBody | ResBody[]] | undefined[]> => {
        setLoading(true);
        // method
        if (!('method' in options)) options.method = method;
        // headers
        if (!('headers' in options) && !ignoreHeaders) options.headers = {
            "Content-Type": "application/json"
        };
        // creds
        if (!('credentials' in options)) options.credentials = auth
            ? "include"
            : "omit";
        // body
        if (!('body' in options) && !['GET', 'DELETE'].includes(options.method || 'GET')) options.body = JSON.stringify(data);
        // url
        let uri: string = endpointURI;
        if (url) uri = url;
        let _response: Response; 
        let _body: ResBody;
        try {
            _response = await fetch(uri, Object.assign(options, moreOptions));
            _body = options.method !== "DELETE" 
                ? await _response.json()
                : "";
            if (inform && options.method !== 'GET') {
                addResponseNote({
                    code: _response.status,
                    message: _response.status >= 200 && _response.status < 300
                        ? successMessage || "success!"
                        : _body.toString() || "unknown error."
                }, notesDispatch);
            }
            setBody(_body);
            setResponse(_response);
            setLoading(false);
            return [_response, _body];
        } catch(error) {
            if (inform) {
                addResponseNote({
                    code: 500,
                    message: (error as Error).message
                }, notesDispatch);
            }
            setError(error as Error);
            setLoading(false);
            return [undefined, undefined];
        }
    }, [
        method, 
        auth, 
        data, 
        endpointURI, 
        successMessage, 
        inform, 
        options,
        notesDispatch,
        ignoreHeaders
    ]);
    useEffect(() => {
        if (trigger) {
            setFired(true);
            setLoading(undefined);
            setResponse(undefined);
            setBody(undefined);
            setError(undefined);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            _fetch();
            setFired(false);
        }
    }, [fired, _fetch]);
    return {
        response: response,
        code: response?.status,
        body: body,
        loading: loading,
        fetch: _fetch,
        error: error
    };
};

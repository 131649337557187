import styled from "styled-components";
import { TFormProps } from "./Form.types";

export const StyledForm = styled.form<Partial<Omit<TFormProps<object>, "onSubmit">>>`
    display: flex;
    margin-top: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
`;
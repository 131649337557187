import { Dispatch, useContext } from "react";
import { INotesState, TNotesAction } from "./NotesContext.types";
import { NotesDispatchContext, NotesStateContext } from "./NotesContext";

export function useNotesDispatch(): Dispatch<TNotesAction> {
    const context = useContext(NotesDispatchContext);
    if (context === undefined) throw new Error("useNotesDispatch outta bounds.");
    return context;
};

export function useNotesState(): INotesState {
    const context = useContext(NotesStateContext);
    if (context === undefined) throw new Error("useNotesState outta bounds.");
    return context;
};

import { RouteMapper } from "@common/components";
import { TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";
import { TFederationRoutes, federationRoutes } from "./FederationLayout.types";
import { Routes } from "react-router";

export const FederationLayout: FunctionComponent<TLayoutProps> = (props) => {
    return (
        <Routes>
            {RouteMapper<TFederationRoutes>({
                routes: federationRoutes
            })}
        </Routes>
    );
};

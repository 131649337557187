import React, { FunctionComponent, useEffect, useState } from "react";
import { TGameStatusPanelProps } from "./GameStatusPanel.types";
import { Box, Button, Text } from "@common/components";
import { GameStatusesEnum } from "@common/enums";
import { TFetchGamesApiGamesData, useFetchGamesApiGames } from "@games-api/hooks";
import { useNavigate } from "react-router";
import { gamesApiRoutes } from "@games-api/routes";

export const GameStatusPanel: FunctionComponent<TGameStatusPanelProps> = ({
    game,
    refresh,
    ...props
}) => {
    const navigate = useNavigate();
    // const [_game, setGame] = useState<TGamesApiGameResponse>(game);
    const [gameData, setGameData] = useState<TFetchGamesApiGamesData>({
        method: "PATCH",
        _gameId: game.id,
        trigger: false
    });
    const { code, loading } = useFetchGamesApiGames(gameData);
    useEffect(() => {
        if (!code) return;
        setGameData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 204) return navigate(gamesApiRoutes.oneEdition.urlBuilder({
            _editionId: game._editionId
        }));
        if (code === 200) {
            return refresh && refresh();
            // setGame(body as TGamesApiGameResponse);
        }
    }, [navigate, code, refresh, game]);
    return (
        <Box
            full
            direction="column"
            aria-label="game status panel"
            style={{
                padding: "1rem 0",
                alignItems: "center"
            }}>
            <Text
                aria-label={`game status: ${game.status}`}
                caps
                text={game.collected 
                    ? "collected"
                    : game.status}
                size="md"
                variant="special"
                component="p" />
            <Box
                full
                direction="row"
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: [
                        GameStatusesEnum.CREATED,
                        GameStatusesEnum.OPEN
                    ].includes(game.status) 
                        ? "1rem 0"
                        : 0,
                    padding: 0
                }}>
                {game.status === GameStatusesEnum.CREATED && (
                    <React.Fragment>
                        <Button
                            caps
                            onClick={(event) => setGameData((prev) => ({
                                ...prev,
                                method: "PATCH",
                                _gameId: game.id,
                                data: {
                                    status: GameStatusesEnum.OPEN
                                },
                                trigger: true
                            }))}
                            label="open"
                            size="sm"
                            nature="accept"
                            style={{
                                marginLeft: 0
                            }} />
                        <Button
                            caps
                            onClick={(event) => setGameData((prev) => ({
                                ...prev,
                                method: "DELETE",
                                _gameId: game.id,
                                trigger: true
                            }))}
                            label="cancel"
                            size="sm"
                            nature="decline"
                            style={{
                                marginRight: 0
                            }} />
                    </React.Fragment>
                )}
                {game.status === GameStatusesEnum.OPEN && (
                    <Button
                        caps
                        onClick={(event) => setGameData((prev) => ({
                            ...prev,
                            method: "PATCH",
                            _gameId: game.id,
                            data: {
                                status: GameStatusesEnum.PLAYING
                            },
                            trigger: true
                        }))}
                        label="close"
                        size="sm"
                        nature="accept" />
                )}
                {game.status === GameStatusesEnum.AWARDED && !game.collected && (
                    <Button
                        caps
                        onClick={(event) => setGameData((prev) => ({
                            ...prev,
                            method: "PATCH",
                            _gameId: game.id,
                            data: {
                                // status: GameStatusesEnum.PLAYING
                                collected: true
                            },
                            trigger: true
                        }))}
                        label="collect"
                        size="sm"
                        loading={loading}
                        nature="accept" />
                )}
            </Box>
        </Box>
    );
};

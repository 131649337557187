import { Container, ItemBox, Text, Title } from "@common/components";
import { TPageProps } from "@common/types";
import { EditionForm } from "@edition/components";
import React, { FunctionComponent } from "react";

export const NewEdition: FunctionComponent<TPageProps> = ({
    ...props
}) => {
    return (
        <Container
            style={{
                alignItems: "center",
            }}>
            <Text
                component="p"
                size="lg"
                variant="special"
                text="new"
                style={{
                    marginBottom: "-1.5rem"
                }} />
            <Title
                text="Edition" 
                size="xxl" />
            <ItemBox
                style={{
                    margin: "2rem 0"
                }}>
                <EditionForm />
            </ItemBox>
        </Container>
    );
};

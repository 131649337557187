import { useCheckUser } from "@auth/hooks/useCheckUser";
import { Container, Loader } from "@common/components";
import { ILayoutRoute, TLayoutProps } from "@common/types";
import React, { FunctionComponent } from "react";

export const CheckUser: FunctionComponent<TLayoutProps & Partial<ILayoutRoute>> = ({
    children,
    ...props
}) => {
    const {complete} = useCheckUser();
    return (
        <React.Fragment>
            {complete 
                ? children
                : (
                    <Container
                        xs="0"
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}>
                        <Loader />
                    </Container>
                )}
        </React.Fragment>
    );
};

import { ENotesActionType, INotesState, TNotesAction } from "./NotesContext.types";

export const INIT_NOTES_STATE: INotesState = {
    responseNotes: []
};

export function initNotesState(incomingState?: Partial<INotesState>): INotesState {
    return Object.assign(
        INIT_NOTES_STATE,
        incomingState
    );
};

export function notesContextReducer(
    state: INotesState,
    action: TNotesAction
): INotesState {
    switch(action.type) {
    case ENotesActionType.addResponseNote:
        return {
            ...state,
            responseNotes: [
                ...state.responseNotes,
                action.payload
            ]
        };
    case ENotesActionType.dismissResponseNote:
        return {
            ...state,
            responseNotes: state.responseNotes.filter(
                (rn) => rn.hash !== action.payload
            )
        };
    }
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ITeamFormInputs, TTeamFormProps } from "./TeamForm.types";
import { Avatar, Form, IField, IFormButton } from "@common/components";
import { SportsEnum } from "@common/enums";
import { useAppState } from "@app/context";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TSelectProps } from "@common/components/Select/Select.types";
import { TeamNaturesEnum } from "@team/enums";
import { TAvatarBody } from "@common/components/Avatar";
import { TFetchTeamAvatarData, TFetchTeamsData, TTeamResponse, useFetchTeamAvatars, useFetchTeams } from "@team/hooks";
import { teamRoutes } from "@team/routes";
import { useNavigate } from "react-router";
import { TeamFederationForm } from "../TeamFederationForm";

export const TeamForm: FunctionComponent<TTeamFormProps> = ({
    team,
    refresh,
    ...props
}) => {
    const navigate = useNavigate();
    const appState = useAppState();
    const [avatar, setAvatar] = useState<TAvatarBody | undefined>(
        team && team.avatar
            ? {avatar: team.avatar, file: undefined}
            : undefined
    );
    const [teamData, setTeamData] = useState<TFetchTeamsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: teamCode,
        body: teamBody,
        loading: teamLoading
    } = useFetchTeams(teamData);
    const [avatarData, setAvatarData] = useState<TFetchTeamAvatarData>({
        method: "POST",
        trigger: false
    });
    const {
        code: avatarCode,
        loading: avatarLoading
    } = useFetchTeamAvatars(avatarData);
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<ITeamFormInputs>>();
    const federations: TSelectProps["options"] = useMemo(() => {
        if (!appState.federations) return [];
        return appState.federations.map((fed) => ({
            value: fed._id,
            label: fed.acronym
        }));
    }, [appState.federations]);
    const countries: TSelectProps["options"] = useMemo(() => {
        return appState.countries.map((country) => ({
            value: country._id,
            label: country.name
        }));
    }, [appState.countries]);
    const fields: IField<ITeamFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            name: "name",
            validations: {
                required: "name is required 😐",
                validate: (value: string) => value.length > 4 
                    ? true 
                    : "name must be longer than 4 characters 😐"
            },
            mode: "textarea",
            defaultValue: team?.name || "",
            modeProps: {
                rows: 3,
                placeholder: "TEAM NAME"
            }
        }, {
            row: 2,
            name: "code",
            validations: {
                required: "code is required 😐",
                validate: (value: string) => value.length > 2
                    ? true
                    : "code must be longer than 2 characters 😐"
            },
            mode: "input",
            defaultValue: team?.code || "",
            modeProps: {
                placeholder: "CODE",
                type: "text"
            }
        }, {
            row: 2,
            name: "sport",
            validations: {
                required: "a sport is required 😐"
            },
            mode: "select",
            defaultValue: team?.sport || "",
            modeProps: {
                placeholder: "SPORT"
            },
            select: {
                options: Object.entries(SportsEnum).map(
                    ([name, value]) => ({
                        label: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
                        value: value
                    })
                ),
                allowNull: false
            }
        }, {
            row: 3,
            name: "_countryId",
            validations: {
                required: "a country is required 😐"
            },
            defaultValue: team?._countryId || "",
            mode: "select",
            modeProps: {
                placeholder: "COUNTRY"
            },
            select: {
                options: countries,
                allowNull: false
            }
        }, {
            row: 3,
            name: "nature",
            validations: {
                required: "a team must have a nature 😐"
            },
            mode: "select",
            defaultValue: team?.nature || "",
            modeProps: {
                placeholder: "NATURE"
            },
            select: {
                options: Object.entries(TeamNaturesEnum).map(
                    ([name, value]) => ({
                        label: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
                        value: value
                    })
                ),
                allowNull: false
            }
        }];
        if (!team) {
            _fields.push({
                row: 4,
                name: "_federationId",
                defaultValue: "",
                mode: "select",
                modeProps: {
                    placeholder: "FEDERATION"
                },
                select: {
                    options: federations,
                    allowNull: true
                }
            }); 
        }
        return _fields;
    }, [countries, federations, team]);
    const hasAvatarChanged = useMemo(() => {
        if (!team) return true;
        if (avatar?.file) return true;
        return false;
    }, [avatar?.file, team]);
    const hasChanged = useMemo(() => {
        if (!team) return true;
        if (!currentValues) return true;
        for (const key in currentValues) {
            if (
                currentValues[key as keyof ITeamFormInputs]
                !== team[key as keyof ITeamFormInputs]
            ) return true;
        }
        return false;
    }, [team, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: team ? "update" : "create",
            size: "sm",
            caps: true,
            type: "submit",
            loading: teamLoading || avatarLoading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            },
            disabled: team && !hasChanged && !hasAvatarChanged
        }];
        return _buttons;
    }, [teamLoading, team, avatarLoading, hasChanged, hasAvatarChanged]);
    const onSubmit: SubmitHandler<ITeamFormInputs> = useCallback(
        async (payload) => {
            if (!team) {
                setTeamData((prev) => ({
                    ...prev,
                    data: {
                        ...payload,
                        sport: payload.sport as SportsEnum,
                        nature: payload.nature as TeamNaturesEnum
                    },
                    trigger: true
                }));
                return;
            }
            if (hasChanged) {
                setTeamData((prev) => ({
                    ...prev,
                    data: {
                        name: payload.name !== team.name
                            ? payload.name
                            : undefined,
                        code: payload.code !== team.code
                            ? payload.code
                            : undefined,
                        _countryId: payload._countryId !== team._countryId
                            ? payload._countryId
                            : undefined,
                        sport: payload.sport !== team.sport
                            ? payload.sport as SportsEnum
                            : undefined,
                        nature: payload.nature !== team.nature 
                            ? payload.nature as TeamNaturesEnum
                            : undefined
                    },
                    method: "PATCH",
                    _teamId: team._id,
                    trigger: true
                }));
            } else if (hasAvatarChanged) {
                setAvatarData((prev) => ({
                    ...prev,
                    method: avatar?.avatar
                        ? "PATCH"
                        : "POST",
                    _avatarId: avatar?.avatar
                        ? avatar.avatar._id
                        : undefined,
                    data: {
                        _teamId: team._id,
                        file: avatar?.file,
                        avatar: avatar?.avatar
                    },
                    trigger: true
                }));
            }
        }, [avatar, team, hasChanged, hasAvatarChanged]); 
    const _Avatar = useMemo(() => {
        return (
            <Avatar
                edit
                item="team"
                avatar={avatar}
                setAvatar={setAvatar}
                size="xxl"
                squared
                style={{
                    margin: "2rem 0 1rem 0"
                }} />
        );
    }, [avatar]);
    useEffect(() => {
        if (!teamCode) return;
        setTeamData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (teamCode === 201) {
            if (!avatar?.file) {
                navigate(teamRoutes.teams.url);
                return;
            }
            setAvatarData((prev) => ({
                ...prev,
                data: {
                    _teamId: (teamBody as TTeamResponse)._id,
                    file: avatar?.file,
                    avatar: avatar?.avatar
                },
                trigger: true
            }));
        }
        if (teamCode === 200) {
            if (!avatar?.file) {
                refresh && refresh();
                return;
            }
            if (!hasChanged) return;
            setAvatarData((prev) => ({
                ...prev,
                method: avatar?.avatar
                    ? "PATCH"
                    : "POST",
                _avatarId: avatar?.avatar
                    ? avatar.avatar._id
                    : undefined,
                data: {
                    _teamId: (teamBody as TTeamResponse)._id,
                    file: avatar?.file,
                    avatar: avatar.avatar
                },
                trigger: true
            }));
        }
    }, [teamCode, teamBody, navigate, avatar, refresh, hasChanged]);
    useEffect(() => {
        if (!avatarCode) return;
        setAvatarData((prev) => ({
            ...prev,
            trigger: false
        }));
        refresh && refresh();
        if (avatarCode === 201) {
            navigate(teamRoutes.teams.url);
        }
    }, [avatarCode, navigate, refresh]);
    useEffect(() => {
        if (!team) return;
        if (!avatar?.file) return;
        if (team.avatar?.secureUrl !== avatar.avatar?.secureUrl) {
            setAvatar({
                avatar: team?.avatar,
                file: undefined
            });
        }
    }, [team, avatar]);
    return (
        <React.Fragment>
            {_Avatar}
            <Form
                aria-label="team-form"
                name={`team form ${team?._id || "new team"}`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}
                {...props} />
            {team && (
                <TeamFederationForm 
                    team={team}
                    refresh={refresh} />
            )}
        </React.Fragment>
    );
};

import { useCallback, useState } from "react";
import { IUseEndlessDateSlider } from "./useEndlessDateSlider.types";

export function useEndlessDateSlider(
    startDate: Date
): IUseEndlessDateSlider {
    const [current, setCurrent] = useState<Date>(new Date(
        startDate.toDateString()
    ));
    const prev = useCallback(() => {
        if (!current) return;
        const previousDate = new Date(
            current.getTime() - 24 * 60 * 60 * 1000
        );
        setCurrent(previousDate);
    }, [current]);
    const next = useCallback(() => {
        if (!current) return;
        const nextDate = new Date(
            current.getTime() + 24 * 60 * 60 * 1000
        );
        setCurrent(nextDate);
    }, [current]);
    return {
        current: current,
        prev: prev,
        next: next
    };
};

import React, { FunctionComponent } from "react";
import { StyledContainer } from "./Container.styles";
import { TContainerProps } from "./Container.types";

export const Container: FunctionComponent<TContainerProps> = ({
    fluid=undefined,
    ...props
}) => {
    return (
        <StyledContainer 
            data-testid={"container"} 
            fluid={fluid} 
            {...props}
        >{props.children}</StyledContainer>
    );
};

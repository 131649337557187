import styled from "styled-components";
import { buttonNatures, buttonSizes, TButtonProps } from "./Button.types";

export const StyledButton = styled.button<Partial<TButtonProps>>`
    width: auto;
    padding: ${props => props.size
        ? buttonSizes[props.size].padding
        : buttonSizes.sm.padding
};
    margin: ${props => props.size === "xs"
        ? "0.5rem 0"
        : "1rem"};
    font-family: ${props => props.theme.fonts['main']};
    font-weight: 400;
    font-size: ${props => props.size
        ? buttonSizes[props.size].fontSize
        : buttonSizes.sm.fontSize
};
    background-color: ${props => props.nature
        ? buttonNatures[props.theme.mode][props.disabled ? "disabled" : props.nature].backgroundColor
        : buttonNatures[props.theme.mode][props.disabled ? "disabled" : "action"].backgroundColor
};
    color: ${props => props.nature
        ? buttonNatures[props.theme.mode][props.disabled ? "disabled" : props.nature].color
        : buttonNatures[props.theme.mode][props.disabled ? "disabled" : "action"].color
};
    border-color: ${props => props.nature
        ? buttonNatures[props.theme.mode][props.nature].borderColor
        : buttonNatures[props.theme.mode].action.borderColor
};
    border-width: ${props => props.size
        ? buttonSizes[props.size].border.width
        : buttonSizes.sm.border.width
};
    cursor: ${props => props.disabled 
        ? "default" 
        : "pointer"};
    box-shadow: ${props => !props.pushed
        ? props.size === "xs"
            ? "3px 3px 0px 0px #22222266 inset, -3px -3px 0px 0px #22222299 inset"
            : "5px 5px 0px 0px #22222266 inset, -5px -5px 0px 0px #22222299 inset"
        : props.size === "xs"
            ? "3px 3px 0px 0px #22222299 inset, -3px -3px 0px 0px #22222266 inset"
            : "5px 5px 0px 0px #22222299 inset, -5px -5px 0px 0px #22222266 inset"
};
`;

import { createGlobalStyle, GlobalStyleComponent } from "styled-components";
import { ThemeType } from "theme/theme.types";

export const GlobalStyle: GlobalStyleComponent<{bg: string, theme: ThemeType}, ThemeType> = createGlobalStyle`
    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body {
        background-color: ${(props) => props.bg};
    }
`;

import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { TTeamBoxProps } from "./TeamBox.types";
import { ItemBox } from "@common/components";
import { TeamForm } from "../TeamForm";
import { TFetchTeamsData, TTeamResponse, useFetchTeams } from "@team/hooks";

export const TeamBox: FunctionComponent<TTeamBoxProps> = ({
    team,
    ...props
}) => {
    const [_team, setTeam] = useState<TTeamResponse>(team);
    const [teamData, setTeamData] = useState<TFetchTeamsData>({
        _teamId: team._id,
        method: "GET",
        trigger: false
    });
    const {
        code,
        body
    } = useFetchTeams(teamData);
    const refresh = useCallback(() => {
        setTeamData((prev) => ({
            ...prev,
            trigger: true
        }));
    }, []);
    useEffect(() => {
        if (!code) return;
        setTeamData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setTeam(body as TTeamResponse);
        }
    }, [code, body]);
    return (
        <ItemBox
            style={{
                margin: "2rem 0"
            }}
            stylesFrom={{
                lg: {
                    margin: "2rem 1rem"
                }
            }}
            aria-label="team-box"
            {...props}>
            <TeamForm
                refresh={refresh} 
                team={_team} />
        </ItemBox>
    );
};

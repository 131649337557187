import React, { ComponentPropsWithRef, FunctionComponent } from "react";
import { StyledInput } from "./Input.styles";
import { TInputProps } from "./Input.types";
import { StyledTextArea } from "./TextArea.styles";
import { Select } from "../Select/Select";

export const Input: FunctionComponent<TInputProps> = ({
    mode,
    modeProps,
    onChange,
    ...props
}) => {
    return (
        <React.Fragment>
            {mode === "input" && 
                <StyledInput
                    mode={mode}
                    removeStepper={props.removeStepper}
                    fontSize={props.fontSize}
                    onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    name={props.name}
                    style={props.style}
                    role={(modeProps as ComponentPropsWithRef<"input">)?.type 
                        ? (modeProps as ComponentPropsWithRef<"input">).type=== "checkbox"
                            ? "checkbox"
                            : "textbox"
                        : "textbox"}
                    {...modeProps as ComponentPropsWithRef<"input">}
                />
            }
            {mode === "textarea" && 
                <StyledTextArea
                    mode={mode}
                    fontSize={props.fontSize}
                    onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
                    name={props.name}
                    style={props.style
                        ? Object.assign({
                            resize: "none"
                        }, props.style)
                        : {
                            resize: "none"
                        }}
                    role="textbox"
                    value={props.value}
                    defaultValue={props.defaultValue}
                    {...modeProps as ComponentPropsWithRef<"textarea">}
                />
            }
            {mode === "select" &&
                <Select 
                    options={props.select?.options}
                    optionGroups={props.select?.optionGroups}
                    fontSize={props.fontSize}
                    onChange={onChange as React.ChangeEventHandler<HTMLSelectElement>}
                    allowNull={props.select?.allowNull}
                    name={props.name}
                    style={props.style}
                    role="listbox"
                    value={props.value}
                    defaultValue={props.defaultValue}
                    {...modeProps as ComponentPropsWithRef<"select">}
                />}
        </React.Fragment>
    );
};

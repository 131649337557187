import React, { FunctionComponent } from "react";
import { WithLoader } from "@common/components";
import { StyledButton } from "./Button.styles";
import { TButtonProps } from "./Button.types";

export const Button: FunctionComponent<TButtonProps> = ({
    caps=false,
    label,
    children,
    loading,
    style,
    _Icon,
    loader,
    ...props
}) => {
    return (
        <StyledButton 
            data-testid="button"
            {...props}
            style={style}>
            <WithLoader 
                {...loader} 
                loading={loading}
                height="auto">
                {_Icon && <_Icon />}
                {label 
                    ? caps
                        ? label.toUpperCase()
                        : label 
                    : children 
                        ? typeof children === "string"
                            ? caps
                                ? children.toUpperCase()
                                : children
                            : children
                        : "label"}
            </WithLoader>
        </StyledButton>
    );
};


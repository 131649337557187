import { ILayoutRoute } from "@common/types";
import { NewMatch } from "@match/pages";
import { lazy } from "react";
const Matches = lazy(() => import("@match/pages").then(({
    Matches
}) => ({default: Matches})));

export type TMatchRoutes = "matches"
    | "newMatch";

export const matchRoutes: Record<TMatchRoutes, ILayoutRoute> = {
    matches: {
        _id: 1,
        patterns: [""],
        url: "/matches",
        element: Matches,
        protect: true, 
        urlBuilder: () => "/matches"
    },
    newMatch: {
        _id: 2,
        patterns: ["/new"],
        url: "/matches/new",
        element: NewMatch,
        protect: true,
        urlBuilder: () => "/matches/new"
    }
};

import React, { FunctionComponent } from "react";
import { Container, Text, Icon } from "@common/components";
import { StyledNavbar } from "./Navbar.styles";
import { TNavbarProps } from "./Navbar.types";
import { Box } from "../Box";
import { useTheme } from "styled-components";
import { Outlet, useNavigate } from "react-router";
import { Donkey } from "../Icon";

export const Navbar: FunctionComponent<TNavbarProps> = ({
    options,
    isotype,
    isotypeAction,
    children,
    ...props
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <StyledNavbar role="navigation" {...props}>
                <Container
                    xs="0px"
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                    <Box full style={{
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Box style={{padding: 0}}>
                            {isotype && (
                                <Icon
                                    size="lg"
                                    role="figure"
                                    onClick={isotypeAction}
                                    style={{
                                        transform: `scaleX(${theme.mode === "light"
                                            ? "1"
                                            : "-1"})`
                                    }}>
                                    <Donkey nature="standard" />
                                </Icon>
                            )}
                            {options?.map((option, index) => (
                                <Text
                                    size="sm" 
                                    style={{margin: "0 1rem"}} 
                                    variant="active" 
                                    key={index}
                                    onClick={(e: React.MouseEvent) => {
                                        navigate(option.path, {
                                            replace: true
                                        });
                                    }}
                                    text={option.name} />
                            ))}
                        </Box>
                        {children}
                    </Box>
                </Container>
            </StyledNavbar>
            <Outlet />
        </React.Fragment>
    );
};
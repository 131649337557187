import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ITournamentFormInputs, TTournamentFormProps } from "./TournamentForm.types";
import { Form, IField, IFormButton } from "@common/components";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TFetchTournamentsData, useFetchTournaments } from "@games-api/hooks";

export const TournamentForm: FunctionComponent<TTournamentFormProps> = ({
    tournament,
    refresh,
    closeForm,
    ...props
}) => {
    const [currentValues, setCurrentValues] = useState<
        DeepPartialSkipArrayKey<ITournamentFormInputs>
    >();
    const [tournamentData, setTournamentData] = useState<TFetchTournamentsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: tournamentCode,
        // body: tournamentBody,
        loading: loading
    } = useFetchTournaments(tournamentData);
    const fields: IField<ITournamentFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            name: "name",
            mode: "input",
            defaultValue: tournament?.name || "",
            validations: {
                required: "name is required 😐"
            },
            modeProps: {
                placeholder: "TOURNAMENT NAME"
            }
        }, {
            row: 2,
            name: "avatarUrl",
            mode: "input",
            defaultValue: tournament?.avatarUrl || "",
            modeProps: {
                placeholder: "AVATAR URL"
            }
        }, {
            row: 3,
            name: "avatarSecureUrl",
            mode: "input",
            defaultValue: tournament?.avatarSecureUrl || "",
            modeProps: {
                placeholder: "AVATAR SECURE URL"
            }
        }];
        return _fields;
    }, [tournament]);
    const hasChanged = useMemo(() => {
        if (!tournament) return true;
        if (!currentValues) return true;
        if (
            currentValues["name"] !== tournament.name || 
            currentValues["avatarUrl"] !== tournament.avatarUrl ||
            currentValues["avatarSecureUrl"] !== tournament.avatarSecureUrl
        ) return true;
        return false;
    }, [tournament, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: tournament ? "update" : "create",
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            },
            style: {
                marginLeft: 0
            },
            disabled: !hasChanged
        }];
        _buttons.push({
            row: 1,
            label: "cancel",
            size: "sm",
            caps: true,
            type: "button",
            nature: "decline",
            style: {
                marginRight: 0
            },
            onClick: (event) => closeForm && closeForm(false)
        });
        return _buttons;
    }, [tournament, loading, hasChanged, closeForm]);
    const onSubmit: SubmitHandler<ITournamentFormInputs> = useCallback(
        async(payload) => {
            if (!tournament) {
                // create a tournament on games-api 
                // through competitions-api
                setTournamentData((prev) => ({
                    ...prev,
                    data: payload,
                    trigger: true
                }));
                return;
            }
            // update a tournament on games-api
            // through competitions-api
            if (hasChanged) {
                setTournamentData((prev) => ({
                    ...prev,
                    method: "PATCH",
                    data: payload,
                    _tournamentId: tournament._id,
                    trigger: true
                }));
            }
        }, [tournament, hasChanged]);
    useEffect(() => {
        if (tournamentCode === 201) {
            setCurrentValues({name: ""});
            return refresh && refresh();
        }
    }, [tournamentCode, refresh]);
    return (
        <React.Fragment>
            <Form
                aria-label="tournament-form"
                name={`tournament form ${tournament?._id || "new tournament"}`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}
                {...props} />
        </React.Fragment>
    );
};

import { TSize } from "@theme";
import { ComponentPropsWithRef } from "react";
import { TThingProps } from "./Thing";
import { TDonkeyProps } from "./Donkey";

export type TIconSize = TSize 
    | "mini" 
    | "huge" 
    | "giant" 
    | "flag";

export type TIconProps = ComponentPropsWithRef<"svg"> & {
    size?: TIconSize;
    nature?: TThingProps["nature"];
};

export type TIconNature = TThingProps 
    | TDonkeyProps;

export const iconSizes: Record<TIconSize, string> = {
    mini: "16px",
    xs: "24px",
    sm: "32px",
    md: "48px",
    lg: "64px",
    xl: "96px",
    xxl: "128px",
    huge: "256px",
    giant: "512px",
    flag: "80px"
};

import styled from "styled-components";
import { Container } from "../Container";
import { TResponseNotesProps } from "./ResponseNotes.types";

export const StyledResponseNotes = styled(Container)<TResponseNotesProps>`
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0, 4rem);
    align-items: center;
    z-index: 1000;
    padding-top: 0.5rem;
    pointer-events: none;
`;

import styled from "styled-components";
import { TUserMenuProps } from "./UserMenu.types";

export const StyledUserMenu = styled.div<Partial<TUserMenuProps>>`
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 1rem 0 0;
    ${props => props.onClick && "cursor: pointer;"}
`;
import React, { FunctionComponent } from "react";
import { TSelectProps } from "./Select.types";
import { StyledSelect } from "./Select.styles";
import { StyledSpanArrow } from "./SpanArrow.styles";

export const Select: FunctionComponent<TSelectProps> = ({
    options,
    optionGroups,
    allowNull = false,
    ...props
}) => {
    return (
        <div style={{
            position: "relative",
            margin: 0,
            padding: 0,
            width: "100%"
        }}>
            <StyledSelect
                role="listbox"
                name={props.name}
                placeholder={props.placeholder || ""}
                required={props.required}
                {...props}>
                <option
                    role="option"
                    value={""}
                    label={props.placeholder || ""}
                    hidden={!allowNull}
                    disabled={!allowNull} />
                {optionGroups?.map((group, groupIndex) => (
                    <optgroup 
                        key={groupIndex}
                        label={group.label}>
                        {group.options?.map((option, index) => (
                            <option
                                key={index}
                                role="option"
                                value={option.value}
                                label={option.label} />
                        ))}
                    </optgroup>
                ))}
                {options?.map((option, index) => (
                    <option
                        key={index}
                        role="option"
                        value={option.value}
                        label={option.label} />
                ))}
            </StyledSelect>
            <StyledSpanArrow />
        </div>
    );
};

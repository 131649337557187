import styled from "styled-components";

export const StyledTable = styled.table`
    font-family: ${props => props.theme.fonts.main};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const StyledTableHeader = styled.thead`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5rem;
`;

export const StyledTableRow = styled.tr`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding: 0.5rem;
`;
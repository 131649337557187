import { useEffect, useState } from "react";
import { IDocumentMeta, TDocumentMetaData } from "./useDocumentMeta.types";

export function useDocumentMeta({
    title,
    description
}: TDocumentMetaData): IDocumentMeta {
    const [_meta, _setMeta] = useState<TDocumentMetaData>({
        title: title,
        description: description
    });
    useEffect(() => {
        document.title = _meta.title;
        const metas = document.querySelectorAll("meta");
        const descriptionMeta = Array.from(metas).find(
            m => m.getAttribute("name") === "description"
        );
        if (!descriptionMeta) return;
        descriptionMeta.content = description;
    }, [_meta.title, description]);
    return [_meta, _setMeta];
};


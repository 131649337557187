import { ILayoutRoute } from "@common/types";
import { lazy } from "react";
const Landing = lazy(() => import("@app/pages").then(({
    Landing
}) => ({default: Landing})));
const FederationLayout = lazy(() => import("@federation/routes").then(({
    FederationLayout
}) => ({
    default: FederationLayout
})));
const CompetitionLayout = lazy(() => import("@competition/routes").then(({
    CompetitionLayout
}) => ({
    default: CompetitionLayout
})));
const EditionLayout = lazy(() => import("@edition/routes").then(({
    EditionLayout
}) => ({
    default: EditionLayout
})));
const TeamLayout = lazy(() => import("@team/routes").then(({
    TeamLayout
}) => ({
    default: TeamLayout
})));
const MatchLayout = lazy(() => import("@match/routes").then(({
    MatchLayout
}) => ({
    default: MatchLayout
})));
const GamesApiLayout = lazy(() => import("@games-api/routes").then(({
    GamesApiLayout
}) => ({
    default: GamesApiLayout
})));

export type TAppRoutes = "home" 
    | "matches"
    | "federations"
    | "teams"
    | "competitions"
    | "editions"
    | "gamesApi";

export const appRoutes: Record<TAppRoutes, ILayoutRoute> = {
    home: {
        _id: 1,
        patterns: ["/", "/home"],
        url: "/",
        element: Landing,
        urlBuilder: () => "/"
    },
    federations: {
        _id:2,
        patterns: ["/federations/*"],
        url: "/federations",
        urlBuilder: () => "/federations",
        element: FederationLayout,
        layout: true,
        protect: true
    },
    teams: {
        _id: 3,
        patterns: ["/teams/*"],
        url: "/teams",
        element: TeamLayout,
        layout: true,
        protect: true,
        urlBuilder: () => "/teams"
    },
    competitions: {
        _id: 4,
        patterns: ["/competitions/*"],
        url: "/competitions",
        element: CompetitionLayout,
        layout: true,
        protect: true,
        urlBuilder: () => "/competitions"
    },
    editions: {
        _id: 5,
        patterns: ["/editions/*"],
        url: "/editions",
        element: EditionLayout,
        layout: true,
        protect: true,
        urlBuilder: () => "/editions"
    },
    matches: {
        _id: 6,
        patterns: ["/matches/*"],
        url: "/matches",
        element: MatchLayout,
        layout: true,
        protect: true,
        urlBuilder: () => "/matches"
    },
    gamesApi: {
        _id: 7,
        patterns: ["/games-api/*"],
        url: "/games-api",
        element: GamesApiLayout,
        layout: true,
        protect: true,
        urlBuilder: () => "/games-api"
    }
};

import { EAppActionType, IAppState, TAppAction } from "./AppContext.types";

export const INIT_APP_STATE: IAppState = {
    theme: "light",
    user: undefined,
    authenticated: false,
    countries: [],
    federations: []
};

export function initAppState(incomingState?: Partial<IAppState>): IAppState {
    return Object.assign(
        INIT_APP_STATE,
        incomingState
    );
};

export function appContextReducer(
    state: IAppState,
    action: TAppAction
): IAppState {
    switch(action.type) {
    case EAppActionType.toggleTheme:
        return {
            ...state,
            theme: state.theme === "dark"
                ? "light"
                : "dark"
        };
    case EAppActionType.authenticate:
        return {
            ...state,
            user: action.payload,
            authenticated: true
        };
    case EAppActionType.unauthenticate:
        return {
            ...state,
            user: undefined,
            authenticated: false
        };
    case EAppActionType.setCountries:
        return {
            ...state,
            countries: action.payload
        };
    case EAppActionType.setFederations:
        return {
            ...state,
            federations: action.payload
        };
    };
};

import { useEffect, useState } from "react";
import { IFetchFederations, TFederationBody, TFederationResponse, TFetchFederationsData } from "./useFetchFederations.types";
import { TFetchData, useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { setFederations, useAppDispatch } from "@app/context";

export function useFetchFederations({
    _federationId,
    method,
    data,
    trigger,
    successMessage=`federation successfuly ${method === "POST" 
        ? "created" 
        : "updated"} 👔`,
    inform=true,
    options,
    ignoreHeaders,
    ...props
}: TFetchFederationsData): IFetchFederations {
    const appDispatch = useAppDispatch();
    const [fired, setFired] = useState<boolean>();
    const [hookData, setHookData] = useState<TFetchData<TFederationBody>>({
        endpointURI: _federationId
            ? endpoints.federations.buildUrl({
                _federationId: _federationId
            })
            : endpoints.federations.url,
        trigger: trigger,
        data: data,
        method: method,
        auth: method === "GET"
            ? false
            : true,
        successMessage: successMessage,
        inform: inform,
        options: options
    });
    const {
        response,
        body,
        code,
        loading,
        fetch,
        error
    } = useFetch<TFederationBody, TFederationResponse>(hookData);
    useEffect(() => {
        if (trigger) {
            setFired(true);
        }
    }, [trigger]);
    useEffect(() => {
        if (fired) {
            setHookData((prev) => ({
                ...prev,
                endpointURI: _federationId
                    ? endpoints.federations.buildUrl({
                        _federationId: _federationId
                    })
                    : endpoints.federations.url,
                method: method,
                auth: method !== "GET",
                successMessage: successMessage,
                options: options,
                inform: inform,
                data: data,
                trigger: true
            }));
            setFired(false);
        }
        return () => {
            if (fired) {
                setHookData((prev) => ({
                    ...prev,
                    trigger: false
                }));
            }
        };
    }, [
        fired,
        _federationId,
        method,
        successMessage,
        options,
        inform,
        data
    ]);
    useEffect(() => {
        if (
            code === 200 && 
            method === "GET" &&
            !_federationId
        ) { 
            setFederations(
                body as TFederationResponse[],
                appDispatch
            );
        }
    }, [
        method, 
        _federationId,
        appDispatch, 
        body, 
        code,
        fired
    ]);
    return {
        response,
        body,
        code,
        loading,
        fetch,
        error
    };
};

import React, { ElementRef, FunctionComponent } from "react";
import { Text } from "@common/components";
import { StyledContextMenu, StyledContextMenuItem } from "./ContextMenu.styles";
import { TContextMenuProps } from "./ContextMenu.types";


export const ContextMenu: FunctionComponent<TContextMenuProps> = React.forwardRef<
    ElementRef<"ul">, TContextMenuProps
>(({
    content,
    flip,
    ...props
}, ref) => {
    return (
        <StyledContextMenu {...props} ref={ref} role="menu">
            {content.map((segment, index) => (
                <React.Fragment key={index}>
                    {segment.name && (
                        <Text 
                            size="xs"
                            text={segment.name} />
                    )}
                    {segment.items?.map((item, itemIndex) => (
                        <StyledContextMenuItem 
                            role="menuitem"
                            key={`${index}-${itemIndex}`}    
                            onClick={(e) => {
                                item.action && item.action(e);
                                flip && flip();
                            }}>{item.name}</StyledContextMenuItem>
                    ))}
                    {index < content.length - 1 && <hr />}
                </React.Fragment>
            ))}
        </StyledContextMenu>
    );
});

ContextMenu.displayName = "ContextMenu";

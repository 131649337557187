import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { appRoutes, TAppRoutes } from "./AppLayout.types";
import { DonkeyBar } from "@app/components";
import { TLayoutProps } from "@common/types";
import { RouteMapper, ResponseNotes } from "@common/components";
import { useFetchCountries } from "@common/hooks/useFetchCountries/useFetchCountries";
import { TFetchFederationsData, useFetchFederations } from "@federation/hooks";
import { INIT_NOTES_STATE, initNotesState, notesContextReducer } from "@app/context/NotesContext.reducer";
import { NotesDispatchContext, NotesStateContext } from "@app/context";

export const AppLayout: FunctionComponent<TLayoutProps> = (props) => {
    const router = useMemo(() => {
        return createBrowserRouter(
            createRoutesFromElements(
                <Route element={<DonkeyBar />}>
                    {RouteMapper<TAppRoutes>({
                        routes: appRoutes
                    })}
                </Route>
            )
        );
    }, []);
    const [state, dispatch] = useReducer(
        notesContextReducer,
        INIT_NOTES_STATE,
        initNotesState
    );
    const _RouterProvider = useMemo(() => {
        return <RouterProvider router={router} />;
    }, [router]);
    useFetchCountries();
    const [federationData, setFederationData] = useState<TFetchFederationsData>({
        trigger: true,
        method: "GET"
    });
    const {code} = useFetchFederations(federationData);
    useEffect(() => {
        if (!code) return;
        if (code === 200) setFederationData((prev) => ({
            ...prev,
            trigger: false
        }));
    }, [code]);
    return (
        <React.Fragment>
            <NotesDispatchContext.Provider value={dispatch}>
                <NotesStateContext.Provider value={state}>
                    <ResponseNotes />
                </NotesStateContext.Provider>
                {_RouterProvider}
            </NotesDispatchContext.Provider>
        </React.Fragment>
        
    );
};

import React, { FunctionComponent } from "react";
import { TTitleProps } from "./Title.types";
import { Text } from "../Text";


export const Title: FunctionComponent<TTitleProps> = ({
    ...props
}) => {
    return (
        <Text
            component="h1"
            role="heading"
            aria-label={`${props.text.toLowerCase()} title`}
            {...props} />
    );
};

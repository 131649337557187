import React, { FunctionComponent, useEffect, useState } from "react";
import { TTabbedBoxProps } from "./TabbedBox.types";
import { StyledTab, StyledTabText, StyledTabbedBox, StyledTabsBox } from "./TabbedBox.styles";

export const TabbedBox: FunctionComponent<TTabbedBoxProps> = ({
    tabs,
    initial,
    overrideActive,
    ...props
}) => {
    const [active, setActive] = useState<number>(initial || tabs[0].id);
    useEffect(() => {
        if (!overrideActive) return;
        setActive(overrideActive);
    }, [overrideActive]);
    return (
        <StyledTabbedBox
            direction="column"
            {...props}>
            <StyledTabsBox style={{padding: 0}}>
                {tabs.map((tab, index) => {
                    if (tab.mode === "info") return null;
                    return (
                        <StyledTab
                            aria-label={`${tab.name}-tab`}
                            disabled={tab.disabled}
                            direction="row"
                            key={tab.id}
                            active={tab.id === active}
                            onClick={(e: React.MouseEvent) => !overrideActive && setActive(tab.id)}
                            style={{
                                marginLeft: index !== 0 ? "-3px" : "0"
                            }}>
                            {tab.Icon && <tab.Icon style={{
                                marginRight: tab.id === active
                                    ? "0.25rem"
                                    : "0"}} />}
                            {tab.id === active && (
                                <StyledTabText
                                    size="sm"
                                    disabled={tab.disabled}
                                    variant={"active"}
                                    caps
                                    text={tab.name} />)}
                        </StyledTab>
                    );
                })}
            </StyledTabsBox>
            {tabs.map((tab) => {
                if (tab.id === active) {
                    return (
                        <React.Fragment key={tab.id}>
                            {tab.Component && <tab.Component {...tab.componentProps} />}
                        </React.Fragment>
                    );
                }
            })}
        </StyledTabbedBox>
    );
};

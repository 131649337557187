export function translateDate({
    ms,
    days,
    start=new Date()
}: {
    ms?: number;
    days?: number;
    start?: Date;
}): Date {
    if (ms) return new Date(start.getTime() + ms);
    if (days) start.setDate(start.getDate() + days);
    return start;
};

export function getTimeTo(
    date: Date | string
): string {
    const msLeft: number = new Date(date).getTime() - new Date().getTime();
    const sec = 1000;
    const min = 60 * sec;
    const hour = 60 * min;
    const day = 24 * hour;
    const week = 7 * day;
    if (msLeft < 0) return "0 SECS";
    if (msLeft > week) return `${(msLeft / week).toFixed(0)} ${Math.floor(msLeft / week) === 1
        ? "WEEK"
        : "WEEKS"}`;
    if (msLeft > day) return `${(msLeft / day).toFixed(0)} ${Math.floor(msLeft / day) === 1
        ? "DAY"
        : "DAYS"}`;
    if (msLeft > hour) return `${(msLeft / hour).toFixed(0)} ${Math.floor(msLeft / hour) === 1
        ? "HOUR"
        : "HOURS"}`;
    if (msLeft >  min) return `${(msLeft / min).toFixed(0)} ${Math.floor(msLeft / min) === 1
        ? "MIN"
        : "MINS"}`;
    return `${(msLeft / sec).toFixed(0)} ${Math.floor(msLeft / sec) === 1
        ? "SEC"
        : "SECS"}`;
};

export function isImminent(
    date: string | Date
) {
    const _date = new Date(date);
    return _date.getTime() < translateDate({
        start: new Date(),
        ms: 10 * 60 * 1000
    }).getTime();
};

export function formatDateForInput(
    date: string | Date
) {
    const _date: Date = typeof date === "string"
        ? new Date(date)
        : date;
    return `${_date
        .toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
        .split("/")
        .reverse()
        .join("-")}T${_date
        .toLocaleTimeString()}.${_date
        .getMilliseconds()
        .toString()
        .slice(0,3)
    }`;
};

import React, { ChangeEventHandler, FunctionComponent, useCallback, useMemo } from "react";
import { TMatchTeamFormProps } from "./MatchTeamForm.types";
import { Avatar, Box, Select, TSelectProps } from "@common/components";

export const MatchTeamForm: FunctionComponent<TMatchTeamFormProps> = ({
    team, 
    teams,
    setTeam,
    away,
    ...props
}) => {
    const _teams: TSelectProps["options"] = useMemo(() => {
        if (!teams) return [];
        const _options: typeof _teams = teams.map(
            _team => ({
                label: _team.code.toUpperCase(),
                value: _team._id
            }));
        return _options;
    }, [teams]);
    const avatar = useMemo(() => {
        if (!team) return undefined;
        return team.avatar;
    },  [team]);
    const handleSelect: ChangeEventHandler<HTMLSelectElement> = useCallback(
        async (event) => {
            if (!setTeam) return;
            setTeam(teams?.find(_team => _team._id === event.target.value));
        }, [setTeam, teams]);
    return (
        <Box
            style={{paddingTop: 0}}
            direction="column">
            <Avatar
                squared
                item="team"
                avatar={{avatar: avatar}}
                size="xl"
                style={{
                    margin: "0.5rem 0 1rem 0"
                }} />
            <Select
                name={!away ? "_homeId" : "_awayId"}
                onChange={handleSelect}
                //defaultValue={team ? team._id : ""}
                placeholder={!away ? "HOME": "AWAY"}
                value={team?._id || ""}
                options={_teams} />
        </Box>
    );
};

import { useCallback, useEffect, useState } from "react";
import { IUseEndlessSlider } from "./useEndlessSlider.types";

export function useEndlessSlider<
    T extends object
>(items: T[] | undefined): IUseEndlessSlider<T> {
    const [current, setCurrent] = useState<T>();
    const [_items, _setItems] = useState<T[]>();
    const prev = useCallback(() => {
        if (!_items) return;
        const _temps = [..._items];
        const _prev: T = _temps.pop() as T;
        _setItems((prev) => ([
            _prev,
            ..._temps
        ]));
        setCurrent([_prev, ..._temps][0]);
    }, [_items]);
    const next = useCallback(() => {
        if (!_items) return;
        const _temps = [..._items];
        const _last: T = _temps.shift() as T;
        _setItems((prev) => ([
            ..._temps,
            _last
        ]));
        setCurrent([..._temps, _last][0]);
    }, [_items]);
    useEffect(() => {
        // initial set up ->
        if (items && !_items) {
            _setItems([...items]);
            setCurrent([...items][0]);
            return;
        }
        // factory reset ->
        if (!items && _items) {
            _setItems(undefined);
            setCurrent(undefined);
            return;
        }
        if (items && _items) {
            if (!items.every(item => _items.find(
                _item => _item == item
            ))) {
                _setItems([...items]);
                setCurrent([...items][0]);
            }
        }
    }, [items, _items]);
    return {
        current: current,
        prev: prev,
        next: next,
    };
};

import { useAppState } from "@app/context";
import { Box, Button, Container, Loader, Title, HorizontalSlider } from "@common/components";
import { TStringSliderObject, useEndlessSlider } from "@common/hooks";
import { TPageProps } from "@common/types";
import { CompetitionBox } from "@competition/components";
import { TCompetitionResponse, TFetchCompetitionsData, useFetchCompetitions } from "@competition/hooks";
import { competitionRoutes } from "@competition/routes";
import { TFederationResponse } from "@federation/hooks";
import { TeamNaturesEnum } from "@team/enums";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

export const Competitions: FunctionComponent<TPageProps> = (props) => {
    const appState = useAppState();
    const navigate = useNavigate();
    const [competitions, setCompetitions] = useState<TCompetitionResponse[]>([]);
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        trigger: false,
        method: "GET",
        options: {
            cache: "no-cache"
        }
    });
    const {
        code,
        body,
        loading
    } = useFetchCompetitions(competitionData);
    const {
        current: federation,
        next: nextFederation,
        prev: previousFederation
    } = useEndlessSlider<TFederationResponse>(
        appState.federations.length > 0
            ? appState.federations 
            : undefined
    );
    const teamNatures: TStringSliderObject[] = useMemo(() => {
        const _teamNatures: typeof teamNatures = [{
            label: TeamNaturesEnum.CLUB,
            value: TeamNaturesEnum.CLUB
        }, {
            label: TeamNaturesEnum.NATIONAL,
            value: TeamNaturesEnum.NATIONAL
        }];
        return _teamNatures;
    }, []);
    const {
        current: teamNature,
        next: nextTeamNature,
        prev: previousTeamNature
    } = useEndlessSlider<TStringSliderObject>(teamNatures);
    const filteredCompetitions: TCompetitionResponse[] = useMemo(() => {
        return competitions.filter(competition => competition.teamNature === teamNature?.value);
    }, [competitions, teamNature]);
    useEffect(() => {
        if (!code) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setCompetitions(body as TCompetitionResponse[]);
        }
    }, [code, body]);
    useEffect(() => {
        if (federation) {
            setCompetitionData((prev) => ({
                ...prev,
                trigger: true,
                method: "GET",
                qs: `_federationId=${federation.id || federation._id}`
            }));
        }
    }, [federation]);
    return (
        <Container
            style={{
                alignItems: "center",
            }}>
            <Title
                text="Competitions"
                size="xxl" />
            <HorizontalSlider
                current={federation?.acronym || "loading..."}
                onNext={nextFederation}
                onPrev={previousFederation} />
            <HorizontalSlider
                layer={2}
                current={teamNature?.label || ""}
                onNext={nextTeamNature}
                onPrev={previousTeamNature} />
            <Box
                full
                direction="row"
                style={{justifyContent: "center"}}>
                <Button
                    caps
                    label="new competition"
                    nature="accept" 
                    onClick={e => navigate(competitionRoutes.newCompetition.url)}/>
            </Box>
            <Box
                direction="column"
                stylesFrom={{
                    lg: {
                        flexDirection: "row"
                    }
                }}
                style={{
                    position: "relative",
                    minHeight: "30rem",
                    margin: 0,
                    padding: 0,
                }}>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        {filteredCompetitions.map((competition) => (
                            <CompetitionBox
                                key={competition._id}
                                competition={competition} />
                        ))}
                    </React.Fragment>
                )}
            </Box>
        </Container>
    );
};

import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TGamePrizesTableProps } from "./GamePrizesTable.types";
import { Box, Button, Text } from "@common/components";
import { ITableHeader, ITableRow, Table } from "@common/components/Table";
import { parseToOrdinal } from "@utils/parsers";
import { 
    // TFetchGamesApiGamesData, 
    TGameStanding, 
    // useFetchGamesApiGames 
} from "@games-api/hooks/useFetchGamesApiGames";
import { useFetchGamesApiPrizes } from "@games-api/hooks/useFetchGamesApiPrizes";
import { TFetchGamesApiPrizesData } from "@games-api/hooks/useFetchGamesApiPrizes/useFetchGamesApiPrizes.types";
// import { TGamesApiGameResponse } from "@games-api/types/GamesApiGame.types";

export const GamePrizesTable: FunctionComponent<TGamePrizesTableProps> = ({
    game, 
    standings,
    refresh,
    ...props
}) => {
    // const [_game, setGame] = useState<TGamesApiGameResponse>();
    // const [gameData, setGameData] = useState<TFetchGamesApiGamesData>({
    //     method: "GET",
    //     _gameId: game._id,
    //     trigger: false
    // });
    // const {
    //     code: gameCode,
    //     body: gameBody,
    //     loading: gameLoading
    // } = useFetchGamesApiGames(gameData);
    const [clickedPrize, setClickedPrize] = useState<string>();
    const [prizesData, setPrizesData] = useState<TFetchGamesApiPrizesData>({
        trigger: false,
        method: "PATCH"
    });
    const {
        code: prizeCode,
        body: prizeBody,
        loading: prizeLoading
    } = useFetchGamesApiPrizes(prizesData);
    const prizesHeaders: ITableHeader[] = useMemo(() => {
        const _headers: typeof prizesHeaders = [{
            title: "pos",
            width: "15%",
            alignment: "left"
        }, {
            title: "handle",
            width: "40%",
            alignment: "left"
        }, {
            title: "prize",
            width: "30%"
        }, {
            width: "15%"
        }];
        return _headers;
    }, []);
    const prizesRows: ITableRow[] = useMemo(() => {
        const _rows: typeof prizesRows = [];
        if (!game?.prizes) return [];
        for (const prize of game.prizes) {
            let standing: TGameStanding | undefined;
            if (prize._ticketId) {
                standing = standings?.find((_standing) => _standing._ticketId === prize._ticketId);
            } else {
                standing = standings?.find(
                    (_standing) => _standing.position === prize.position
                );
            }
            _rows.push({
                values: [
                    <Text 
                        key={prize.id}
                        text={prize.position
                            ? parseToOrdinal(prize.position) 
                            : "-"}
                        size="sm" />,
                    <Text   
                        key={prize.id}
                        text={`@${standing?.handle || "-"}`}
                        size="sm" />,
                    <Text
                        key={prize.id}
                        text={(prize.fee * (game.purse || prize.fee * (game.players || 1))).toFixed(4)}
                        size="sm"
                        style={{
                            width: "100%",
                            textAlign: "center"
                        }} />,
                    <Button
                        key={prize.id}
                        caps
                        label={prize.paidFor ? "sent" : "send"}
                        disabled={prizeLoading || prize.paidFor}
                        nature={prize.paidFor ? "disabled" : "action"}
                        pushed={prize.paidFor}
                        style={{
                            padding: "0.2rem 0.8rem",
                            margin: "0 auto"
                        }}
                        size="xs"
                        onClick={(event) => {
                            setPrizesData((prev) => ({
                                ...prev,
                                _prizeId: prize.id,
                                method: "PATCH",
                                data: {
                                    paidFor: true
                                },
                                trigger: true
                            }));
                            setClickedPrize(prize.id);
                        }}
                        loading={prizeLoading && clickedPrize === prize.id} />
                ]
            });
        }
        return _rows;
    }, [game, standings, prizeLoading, clickedPrize]);
    // useEffect(() => {
    //     if (_game) return;
    //     setGame(game);
    // }, [game, _game]);
    // useEffect(() => {
    //     if (!_game) return;
    //     let allPaidFor = true;
    //     for (const _prize of _game.prizes) {
    //         if (_prize.paidFor) continue;
    //         allPaidFor = false;
    //     }
    //     if (allPaidFor) refresh && refresh();
    // }, [_game, refresh]);
    // useEffect(() => {
    //     if (!gameCode) return;
    //     setGameData((prev) => ({
    //         ...prev,
    //         trigger: false
    //     }));
    //     if (gameCode === 200) {
    //         setGame(gameBody as TGamesApiGameResponse);
    //     }
    // }, [gameBody, gameCode]);
    useEffect(() => {
        if (!prizeCode) return;
        setPrizesData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (prizeCode === 200) {
            setClickedPrize(undefined);
            refresh && refresh();
            // setGameData((prev) => ({
            //     ...prev,
            //     trigger: true
            // }));
        }
    }, [prizeCode, prizeBody, refresh]);
    return (
        <Box 
            full
            direction="column"
            style={{
                marginTop: "2rem",
                padding: 0,
                alignItems: "center"
            }}>
            <Text
                size="md"
                caps
                text="prizes"
                variant="special" />
            <Table
                tableName="prizes table"
                rows={prizesRows || []}
                headers={prizesHeaders || []}
                style={{
                    marginTop: "1rem"
                }} />
        </Box>
    );
};
import { TPattern, TPatternName } from "./Snake.types";

export const snakePatterns: Record<TPatternName, TPattern> = {
    circle: {
        board: [
            [0, 0, 1, 1, 1, 0, 0],
            [0, 1, 0, 0, 0, 1, 0],
            [1, 0, 0, 0, 0, 0, 1],
            [1, 0, 0, 0, 0, 0, 1],
            [1, 0, 0, 0, 0, 0, 1],
            [0, 1, 0, 0, 0, 1, 0],
            [0, 0, 1, 1, 1, 0, 0],
        ],
        order: [
            2, 
            3, 
            4, 
            12, 
            20, 
            27,
            34, 
            40,
            46, 
            45, 
            44, 
            36,
            28,
            21, 
            14,
            8,
        ]
    }
};

import { useCallback, useEffect, useState } from "react";
import { TSize, breakpoints } from "@theme";

export function useMediaQuery(
    size: TSize
): boolean | undefined {
    const [result, setResult] = useState<boolean>();
    const handleMediaChange = useCallback(async(
        event?: MediaQueryListEvent
    ) => {
        setResult(window.matchMedia(breakpoints[size]).matches);
    }, [size]);
    useEffect(() => {
        handleMediaChange();
        const matchMedia = window.matchMedia(breakpoints[size]);
        matchMedia.addEventListener("change", handleMediaChange);
        return () => {
            matchMedia.removeEventListener("change", handleMediaChange);
        };
    }, [size, handleMediaChange]);
    return result;
};

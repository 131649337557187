import { Title } from "@common/components";
import React, { FunctionComponent } from "react";
import { TEditionMatchesProps } from "./EditionMatches.types";

export const EditionMatches: FunctionComponent<TEditionMatchesProps> = ({
    ...props
}) => {
    return (
        <Title
            size="xl"
            text="hello edition matches" />
    );
};

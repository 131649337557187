import { fontSizes } from "@theme";
import styled, { css } from "styled-components";
import { TInputProps } from "./Input.types";

export const StyledInput = styled.input<TInputProps>`
    width: 100%;
    margin: 0;
    background-color: ${props => props.theme.palette.inputBackground};
    color: ${props => props.theme.palette.itemDarkText};
    padding: 0.5rem 1rem;
    font-size: ${props => props.fontSize
        ? fontSizes[props.fontSize]
        : fontSizes.sm};
    font-family: ${props => props.theme.fonts.main};
    border: 2px solid ${props => props.theme.palette.borderTheme};
    box-shadow: 5px 5px 0px 0px #22222299 inset, -5px -5px 0px 0px #22222266 inset;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        ${props => props.removeStepper
            && css`
                -webkit-appearance: none;
                margin: 0;
        `}
    }
    ${props => props.removeStepper && `-moz-appearance: textfield;`}
`;
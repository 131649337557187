import { Box, Button, Form, IField } from "@common/components";
import { TEditionResponse, TFetchEditionsData, useFetchEditions } from "@edition/hooks";
import { formatDateForInput, isImminent, translateDate } from "@utils/helpers";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IMatchFormInputs, TMatchFormProps } from "./MatchForm.types";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TFetchTeamsData, TTeamResponse, useFetchTeams } from "@team/hooks";
import { MatchTeamForm } from "../MatchTeamForm";
import { TFetchMatchesData, TMatchBody, useFetchMatches } from "@match/hooks";
import { matchRoutes } from "@match/routes/MatchLayout.types";
import { useNavigate } from "react-router";

export const MatchForm: FunctionComponent<TMatchFormProps> = ({
    match,
    edition,
    refresh,
    ...props
}) => {
    const navigate = useNavigate();
    const [editions, setEditions] = useState<TEditionResponse[]>();
    const [oneEdition, setOneEdition] = useState<TEditionResponse>();
    const [home, setHome] = useState<TTeamResponse>();
    const [away, setAway] = useState<TTeamResponse>();
    const [teams, setTeams] = useState<TTeamResponse[]>();
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<IMatchFormInputs>>();
    const [editionData, setEditionData] = useState<TFetchEditionsData>({
        trigger: false,
        method: "GET"
    });
    const {
        code,
        body
    } = useFetchEditions(editionData);
    const [teamData, setTeamData] = useState<TFetchTeamsData>({
        method: "GET",
        trigger: false
    });
    const {
        code: teamsCode,
        body: teamsBody
    } = useFetchTeams(teamData);
    const [matchData, setMatchData] = useState<TFetchMatchesData>({
        method: match ? "PATCH" : "POST",
        trigger: false
    });
    const {
        code: matchCode,
        body: matchBody,
        loading: matchLoading
    } = useFetchMatches(matchData);
    const fields: IField<IMatchFormInputs>[] | undefined = useMemo(() => {
        if (!match && !editions) return;
        if (match && !oneEdition) return;
        const _fields: typeof fields = [{
            row: 4,
            mode: "input",
            name: "name",
            validations: {
                required: "name is required 😐"
            },
            defaultValue: match?.name || "",
            modeProps: {
                placeholder: "MATCH NAME"
            }
        }, {
            row: 2,
            mode: "input",
            name: "stage",
            validations: {
                required: "stage name is required 😐"
            },
            defaultValue: match?.stage || "",
            modeProps: {
                placeholder: "STAGE NAME"
            },
        }, {
            row: 3,
            mode: "input",
            name: "matchday",
            validations: {
                required: "matchday name is required 😐"
            },
            defaultValue: match?.matchday || "",
            modeProps: {
                placeholder: "MATCHDAY NAME"
            }
        }, {
            row: 5,
            mode: "input",
            name: "startsAt",
            validations: {
                validate: (value: string) => {
                    if (!value) return "a start date and time is required 😐";
                    const _date = new Date(`${value}`);
                    if (isImminent(_date)) return "can't turn into an imminent match 😐";
                    return true;
                }
            },
            defaultValue: match
                ? formatDateForInput(match.startsAt)
                : formatDateForInput(translateDate({
                    days: 1
                })),
            modeProps: {
                type: "datetime-local"
            }
        }];
        // if (!match) {
        _fields.push({
            row: 1,
            mode: "select",
            name: "_editionId",
            validations: {
                required: "edition is required 😐"
            },
            defaultValue: match?._editionId || "",
            hidden: match?._editionId ? true : undefined,
            modeProps: {
                placeholder: "EDITION NAME",
                disabled: false, // match?._editionId ? true : undefined
            },
            select: {
                options: editions?.map((edition) => ({
                    label: `${edition.competition?.name} - ${edition.name}`,
                    value: edition._id || edition.id
                })) || [{
                    label: `${oneEdition?.competition?.name} - ${oneEdition?.name}`,
                    value: oneEdition?._id || oneEdition?.id
                }],
                allowNull: false
            }
        });
        // }
        return _fields;
    }, [match, editions, oneEdition]);
    const hasChanged = useMemo(() => {
        if (!match) return true;
        if (!currentValues) return true;
        for (const key in currentValues) {
            if (key === "startsAt") continue;
            if (
                currentValues[key as keyof IMatchFormInputs]
                !== match[key as keyof IMatchFormInputs]
            ) return true;
        }
        if (
            currentValues.startsAt &&
            new Date(match.startsAt).getTime() 
            !== new Date(`${currentValues.startsAt}`).getTime()
        ) return true;
        if (home && home._id !== match._homeId) return true;
        if (away && away._id !== match._awayId) return true;
        return false;
    }, [match, currentValues, home, away]);
    const onSubmit: SubmitHandler<IMatchFormInputs> = useCallback(
        async(payload) => {
            if (!match) {
                const body: TMatchBody = {
                    ...payload,
                    _homeId: home?._id,
                    _awayId: away?._id,
                    startsAt: new Date(`${payload.startsAt}`)
                };
                setMatchData((prev) => ({
                    ...prev,
                    method: "POST",
                    data: body,
                    trigger: true
                }));
                return;
            }
            if (hasChanged) {
                setMatchData((prev) => ({
                    ...prev,
                    method: "PATCH",
                    _matchId: match._id,
                    data: {
                        stage: payload.stage !== match.stage
                            ? payload.stage
                            : undefined,
                        matchday: payload.matchday !== match.matchday
                            ? payload.matchday
                            : undefined,
                        name: payload.name !== match.name
                            ? payload.name
                            : undefined,
                        _homeId: home?._id !== match._homeId
                            ? home?._id
                            : undefined,
                        _awayId: away?._id !== match._awayId
                            ? away?._id
                            : undefined,
                        startsAt: new Date(
                            `${payload.startsAt}`
                        ).getTime() !== new Date(
                            match.startsAt
                        ).getTime()
                            ? new Date(`${payload.startsAt}`)
                            : undefined
                    },
                    trigger: true
                }));
            }
        }, [home, away, match, hasChanged]);
    useEffect(() => {
        if (match) {
            if (!oneEdition) return;
            const _federationId = oneEdition?.competition?._federationId;
            setTeamData((prev) => ({
                ...prev,
                trigger: true,
                qs: `_federationId=${_federationId}`
            }));
        } else {
            if (
                currentValues?._editionId &&
                currentValues._editionId !== ""
            ) {
                const _federationId = editions?.find(
                    _ed => _ed._id === currentValues._editionId
                )?.competition?._federationId;
                setTeamData((prev) => ({
                    ...prev,
                    qs: `_federationId=${_federationId}`,
                    trigger: true
                }));
            } else {
                setTeams([]);
            }
        }
    }, [match, currentValues?._editionId, editions, oneEdition]);
    useEffect(() => {
        if (!teams) return;
        if (match) {
            setHome(teams.find(_team => _team._id === match._homeId));
            setAway(teams.find(_team => _team._id === match._awayId));
        }
    }, [teams, match]);
    useEffect(() => {
        if (!teamsCode) return;
        setTeamData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (teamsCode === 200) {
            const _teams = teamsBody as TTeamResponse[]; 
            setTeams(_teams.sort(
                (_tA, _tB) => {
                    if (_tA.code > _tB.code) return 1;
                    if (_tA.code < _tB.code) return -1;
                    return 0;
                }
            ));
        }
    }, [teamsCode, teamsBody]);
    useEffect(() => {
        if (!match) {
            setEditionData((prev) => ({
                ...prev,
                trigger: true
            }));
        } else {
            // if (!edition) {
            //     setEditionData((prev) => ({
            //         ...prev,
            //         _editionId: match._editionId,
            //         trigger: true
            //     }));
            // } else {
            setOneEdition(edition);
            // }
        }
    }, [match, edition]);
    useEffect(() => {
        if (!code) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            if (!match) {
                setEditions(body as TEditionResponse[]);
            } else {
                setOneEdition(body as TEditionResponse);
            }
        }
    }, [body, code, match]);
    useEffect(() => {
        if (!matchCode) return;
        setMatchData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (matchCode === 201) {
            navigate(matchRoutes.matches.url);
            return;
        } 
        if (matchCode === 200) {
            refresh && refresh();
            return;
        }
    }, [matchCode, matchBody, refresh, navigate]);
    return (
        <React.Fragment>
            {fields && (
                <React.Fragment>
                    <Form
                        style={{marginTop: match ? 0 : "1rem"}}
                        name={`match form ${match?._id || "new"}`}
                        aria-label="match-form"
                        fields={fields}
                        buttons={[]}
                        onSubmit={onSubmit}
                        setCurrentValues={setCurrentValues}
                        {...props} />
                    {currentValues?._editionId && (
                        <Box
                            full
                            direction="row"
                            style={{
                                justifyContent: "space-between"
                            }}>
                            <MatchTeamForm
                                team={home}
                                teams={teams}
                                setTeam={setHome} />
                            <MatchTeamForm 
                                away
                                team={away}
                                teams={teams}
                                setTeam={setAway} />
                        </Box>
                    )}
                    <Box
                        full
                        direction="row"
                        style={{
                            padding: 0,
                            justifyContent: "center"
                        }}>
                        <Button
                            caps
                            label={match ? "UPDATE" : "CREATE"}
                            disabled={!hasChanged}
                            nature="accept"
                            size="sm"
                            type="submit"
                            form={`match form ${match?._id || "new"}`}
                            loading={matchLoading}
                            loader={{
                                loaderLength: "regular",
                                loaderSize: "sm",
                                speed: "fast",
                                variant: "rainbow"
                            }} />
                        
                    </Box>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
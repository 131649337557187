import { palettes, ThemeTypeMode, TSize } from "@theme";
import { ButtonHTMLAttributes, ComponentPropsWithoutRef, FunctionComponent } from "react";
import { ILoader } from "../Loader/Loader.types";
import { TWithLoaderProps } from "../WithLoader";
import { TIconProps } from "../Icon/Svg.types";

export type TButtonNature = "action"
    | "accept"
    | "decline"
    | "option"
    | "info"
    | "disabled"
    | "collapsed";

export type TButtonSize = Exclude<TSize, "xl" | "xxl">;

export type TButtonProps = ComponentPropsWithoutRef<"button"> & 
    IButton & {
        loader?: TWithLoaderProps<"button">;
        _Icon?: FunctionComponent<TIconProps>;
    };

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: TButtonSize;
    nature?: TButtonNature;
    caps?: boolean;
    label?: string; 
    loading?: boolean;
    loader?: ILoader;
    pushed?: boolean;
}

export type TButtonSpecs = {
    padding: string;
    border: {
        width: string;
    };
    fontSize: string;
};

export const buttonSizes: Record<TButtonSize, TButtonSpecs> = {
    xs: {
        padding: "0.3rem 1rem",
        border: {
            width: "2px"
        },
        fontSize: "1rem"
    },
    sm: {
        padding: "0.8rem 2rem",
        border: {
            width: "2px",
        },
        fontSize: "1.5rem"
    },
    md: {
        padding: "1rem 2.5rem",
        border: {
            width: "3px",
        },
        fontSize: "2rem"
    },
    lg:{
        padding: "1.25rem 3rem",
        border: {
            width: "4px",
        },
        fontSize: "2.5rem"
    },
};

export type TButtonColors = {
    backgroundColor: string;
    color: string;
    borderColor: string;
}

export const buttonNatures: Record<ThemeTypeMode, Record<TButtonNature, TButtonColors>> = {
    light: {
        action: {
            backgroundColor: palettes.light.btnAction,
            color: palettes.light.itemLightText,
            borderColor: palettes.light.borderTheme
        },
        accept: {
            backgroundColor: palettes.light.btnAccept,
            color: palettes.light.itemDarkText,
            borderColor: palettes.light.borderTheme
        },
        info: {
            backgroundColor: palettes.light.btnInfo,
            color: palettes.light.itemLightText,
            borderColor: palettes.light.borderTheme
        },
        decline: {
            backgroundColor: palettes.light.btnDecline,
            color: palettes.light.itemLightText,
            borderColor: palettes.light.borderTheme
        },
        disabled: {
            backgroundColor: palettes.light.btnDisabled,
            color: palettes.light.itemDarkText,
            borderColor: palettes.light.borderTheme
        },
        option: {
            backgroundColor: palettes.light.btnOption,
            color: palettes.light.itemLightText,
            borderColor: palettes.light.borderTheme
        },
        collapsed: {
            backgroundColor: palettes.light.btnCollapsed,
            color: palettes.light.itemDarkText,
            borderColor: palettes.light.borderTheme,
        }
    }, dark: {
        action: {
            backgroundColor: palettes.dark.btnAction,
            color: palettes.dark.itemLightText,
            borderColor: palettes.dark.borderTheme
        },
        accept: {
            backgroundColor: palettes.dark.btnAccept,
            color: palettes.dark.itemDarkText,
            borderColor: palettes.dark.borderTheme
        },
        info: {
            backgroundColor: palettes.dark.btnInfo,
            color: palettes.dark.itemLightText,
            borderColor: palettes.dark.borderTheme
        },
        decline: {
            backgroundColor: palettes.dark.btnDecline,
            color: palettes.dark.itemLightText,
            borderColor: palettes.dark.borderTheme
        },
        disabled: {
            backgroundColor: palettes.dark.btnDisabled,
            color: palettes.dark.itemDarkText,
            borderColor: palettes.dark.borderTheme
        },
        option: {
            backgroundColor: palettes.dark.btnOption,
            color: palettes.dark.itemLightText,
            borderColor: palettes.dark.borderTheme
        },
        collapsed: {
            backgroundColor: palettes.dark.btnCollapsed,
            color: palettes.dark.itemDarkText,
            borderColor: palettes.dark.borderTheme,
        }
    }
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { IEditionFormInputs, TEditionFormProps } from "./EditionForm.types";
import { Avatar, Box, Form, IField, IFormButton, Text } from "@common/components";
import { TSelectProps } from "@common/components/Select/Select.types";
import { TCompetitionResponse, TFetchCompetitionsData, useFetchCompetitions } from "@competition/hooks";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { TFetchEditionsData, useFetchEditions } from "@edition/hooks";
import { editionRoutes } from "@edition/routes/EditionLayout.types";

export const EditionForm: FunctionComponent<TEditionFormProps> = ({
    edition,
    refresh,
    ...props
}) => {
    const [_competitions, setCompetitions] = useState<TCompetitionResponse[]>();
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<IEditionFormInputs>>();
    const navigate = useNavigate();
    const [editionData, setEditionData] = useState<TFetchEditionsData>({
        method: "POST",
        trigger: false
    });
    const {
        code: editionCode,
        loading
    } = useFetchEditions(editionData);
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        trigger: false,
        method: "GET"
    });
    const {
        code,
        body
    } = useFetchCompetitions(competitionData);
    const competitions: TSelectProps["options"] = useMemo(() => {
        if (!_competitions) return;
        return _competitions.map((competition) => ({
            value: competition._id,
            label: competition.name
        }));
    }, [_competitions]);
    const fields: IField<IEditionFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            name: "name",
            mode: "input",
            defaultValue: edition?.name || "",
            validations: {
                required: "name is required 😐"
            },
            modeProps: {
                placeholder: "EDITION NAME"
            }
        }];
        if (!edition) {
            _fields.push({
                row: 2,
                name: "_competitionId",
                mode: "select",
                defaultValue: "",
                validations: {
                    required: "competition is required to create an edition 😐"
                },
                modeProps: {
                    placeholder: "COMPETITION"
                },
                select: {
                    options: competitions,
                    allowNull: false
                }
            });
            _fields.push({
                row: 3,
                name: "blueprint",
                mode: "textarea",
                defaultValue: "",
                validations: {
                    validate: (value: string) => {
                        if (!value) return true;
                        try {
                            JSON.parse(value);
                            return true;
                        } catch(error) {
                            return "not a valid json object, check your syntax 😐";
                        }
                    }
                },
                modeProps: {
                    rows: 5,
                    placeholder: "COMPETITION EDITION JSON BLUEPRINT"
                }
            });
        }
        return _fields;
    }, [competitions, edition]);
    const hasChanged = useMemo(() => {
        if (!edition) return true;
        if (!currentValues) return true;
        if (currentValues["name"] !== edition.name) return true;
        return false;
    }, [edition, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: edition ? "update" : "create",
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            },
            disabled: !hasChanged
        }];
        return _buttons;
    }, [loading, edition, hasChanged]);
    const onSubmit: SubmitHandler<IEditionFormInputs> = useCallback(
        async(payload) => {
            if (!edition) {
                setEditionData((prev) => ({
                    ...prev,
                    data: {
                        ...payload,
                        blueprint: payload.blueprint
                            ? JSON.parse(payload.blueprint || "")
                            : undefined 
                    },
                    trigger: true
                }));
                return;
            }
            if (hasChanged) {
                setEditionData((prev) => ({
                    ...prev,
                    data: {
                        name: payload.name
                    },
                    trigger: true,
                    method: "PATCH",
                    _editionId: edition._id 
                }));
            }
        }, [edition, hasChanged]);
    useEffect(() => {
        if (!edition) setCompetitionData((prev) => ({
            ...prev,
            trigger: true
        }));
    }, [edition]);
    useEffect(() => {
        if (!code) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setCompetitions(body as TCompetitionResponse[]);
        }
    }, [code, body]);
    useEffect(() => {
        if (!editionCode) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (editionCode === 201) return navigate(editionRoutes.editions.url);
        if (editionCode === 200) return refresh && refresh();
    }, [editionCode, refresh, navigate]);
    return (
        <React.Fragment>
            {edition && (
                <Box
                    direction="row">
                    <Avatar
                        size="lg"
                        avatar={{
                            avatar: edition.competition?.avatar,
                            file: undefined
                        }} />
                    <Box
                        direction="column">
                        <Text
                            component="h2"
                            size="md"
                            text={edition.competition?.name || ""} />
                        <Text 
                            component="h4"
                            size="sm"
                            text={edition.competition?.description || ""} />
                    </Box>
                </Box>
            )}
            <Form
                aria-label="edition-form"
                name={`edition form ${edition?._id || "new edition"}`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}
                {...props} />
        </React.Fragment>
    );
};

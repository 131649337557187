import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { TEditionBoxProps } from "./EditionBox.types";
import { Icon, TTab, TabbedBox, Thing } from "@common/components";
import { TIconProps } from "@common/components/Icon/Svg.types";
import { TEditionResponse, TFetchEditionsData, useFetchEditions } from "@edition/hooks";
import { EditionForm, TEditionFormProps } from "../EditionForm";
import { EditionMatches, TEditionMatchesProps } from "../EditionMatches";

export const EditionBox: FunctionComponent<TEditionBoxProps> = ({
    edition,
    ...props
}) => {
    const [_edition, setEdition] = useState<TEditionResponse>(edition);
    const [editionData, setEditionData] = useState<TFetchEditionsData>({
        _editionId: edition._id,
        method: "GET",
        trigger: false
    });
    const {
        code,
        body
    } = useFetchEditions(editionData);
    const refresh = useCallback(() => {
        setEditionData((prev) => ({
            ...prev,
            trigger: true
        }));
    }, []);
    const tabs: TTab[] = useMemo(() => {
        const _tabs: typeof tabs = [{
            id: 1,
            name: "info",
            mode: "form",
            Component: (props: TEditionFormProps) => (
                <EditionForm
                    edition={_edition}
                    refresh={refresh}
                    {...props} />
            ),
            Icon: (props: TIconProps) => (
                <Icon size="xs" {...props}>
                    <Thing nature="info" />
                </Icon>
            )
        }, {
            id: 2,
            name: "matches",
            mode: "data",
            Component: (props: TEditionMatchesProps) => (
                <EditionMatches
                    {...props} 
                    edition={_edition} />
            ),
            Icon: (props: TIconProps) => (
                <Icon size="xs" {...props}>
                    <Thing nature="soccer-ball" />
                </Icon>
            )
        }];
        return _tabs;
    }, [refresh, _edition]);
    useEffect(() => {
        if (!code) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setEdition(body as TEditionResponse);
        }
    }, [code, body]);
    return (
        <TabbedBox
            {...props}
            aria-label="edition-box"
            initial={1}
            tabs={tabs} />
    );
};

import React, { ChangeEventHandler, FunctionComponent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { TMatchScoreFormProps } from "./MatchScoreForm.types";
import { Avatar, Box, Button, Input } from "@common/components";
import { TFetchMatchesData, useFetchMatches } from "@match/hooks";
import { IMatch } from "@match/types";
import { MatchStatusesEnum } from "@common/enums";
import { translateDate } from "@utils/helpers";
import { CSSProperties, useTheme } from "styled-components";

export const MatchScoreForm: FunctionComponent<TMatchScoreFormProps> = ({
    match,
    refresh,
    ...props
}) => {
    const theme = useTheme();
    const [homeGoals, setHomeGoals] = useState<number | undefined>(
        match.homeGoals 
    );
    const [awayGoals, setAwayGoals] = useState<number | undefined>(
        match.awayGoals 
    );
    const [matchData, setMatchData] = useState<TFetchMatchesData>({
        method: "PATCH",
        _matchId: match._id,
        trigger: false
    });
    const {
        code,
        loading
    } = useFetchMatches(matchData);
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        const _value: number = isNaN(parseInt(event.target.value))
            ? 0
            : parseInt(event.target.value);
        if (event.target.name === "homeGoals") return setHomeGoals(_value);
        return setAwayGoals(_value);
    }, []);
    const onSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
        () => {
            const payload: Partial<Pick<IMatch, "homeGoals" | "awayGoals">> = {};
            if (match.homeGoals !== homeGoals) payload.homeGoals = homeGoals;
            if (match.awayGoals !== awayGoals) payload.awayGoals = awayGoals;
            setMatchData((prev) => ({
                ...prev,
                data: payload,
                trigger: true
            }));
        }, [match.homeGoals, match.awayGoals, homeGoals, awayGoals]);
    const isConfirmed: boolean = useMemo(() => {
        return match.status === MatchStatusesEnum.CONFIRMED;
    }, [match.status]);
    const confirmedStyles: Partial<CSSProperties> | undefined = useMemo(() => {
        const _styles: typeof confirmedStyles = {
            width: "3rem",
            maxWidth: "3rem",
            marginTop: "1rem",
            textAlign: "center"
        };
        if (isConfirmed) return Object.assign(_styles, {
            color: theme.palette.terminalFg,
            backgroundColor: theme.palette.terminalBg
        });
        return _styles;
    }, [isConfirmed, theme.palette]);
    const isImminent: boolean = useMemo(() => {
        if (match.status !== MatchStatusesEnum.PLANNED) return false;
        if (
            new Date().getTime() <=
            translateDate({
                ms: - 1000 * 60 * 10,
                start: new Date(match.startsAt)
            }).getTime()
        ) return false;
        return true;
    }, [match]);
    useEffect(() => {
        if (!code) return;
        setMatchData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            refresh && refresh();
        }
    }, [code, refresh]);
    return (
        <React.Fragment>
            {(
                match.status !== MatchStatusesEnum.PLANNED ||
                (
                    match.status === MatchStatusesEnum.PLANNED && 
                    isImminent
                )
            ) && (
                <React.Fragment>
                    <Box
                        full
                        direction="row"
                        style={{
                            justifyContent: "space-between",
                            paddingTop: 0,
                            paddingBottom: 0
                        }}>
                        <Box
                            aria-label="home-score-box"
                            direction="column"
                            style={{alignItems: "center"}}>
                            <Avatar
                                squared
                                item="team"
                                size="xl"
                                avatar={{
                                    avatar: match.home?.avatar
                                }} />
                            {!isImminent && (
                                <Input
                                    aria-label="home-score"
                                    mode="input"
                                    type="number"
                                    value={homeGoals}
                                    onChange={handleChange}
                                    name="homeGoals" 
                                    disabled={isConfirmed}
                                    style={confirmedStyles}/>
                            )}
                        </Box>
                        <Box
                            aria-label="away-score-box"
                            direction="column"
                            style={{alignItems: "center"}}>
                            <Avatar
                                squared
                                item="team"
                                size="xl"
                                avatar={{
                                    avatar: match.away?.avatar
                                }} />
                            {!isImminent && (
                                <Input
                                    aria-label="away-score"
                                    mode="input"
                                    type="number"
                                    value={awayGoals}
                                    onChange={handleChange}
                                    name="awayGoals" 
                                    disabled={isConfirmed}
                                    style={confirmedStyles}/>
                            )}
                        </Box>
                    </Box>
                    {[
                        MatchStatusesEnum.PLAYING,
                        MatchStatusesEnum.FINISHED
                    ].includes(match.status) && (
                        <Box
                            full
                            direction="row"
                            style={{justifyContent: "center"}}>
                            <Button
                                caps
                                nature="action"
                                size="sm"
                                label={"update"}
                                loader={{
                                    speed: "regular",
                                    variant: "rainbow",
                                    loaderLength: "regular",
                                    loaderSize: "sm"
                                }}
                                loading={loading}
                                disabled={
                                    match.homeGoals == homeGoals && 
                                    match.awayGoals == awayGoals
                                }
                                onClick={onSubmit} />
                        </Box>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
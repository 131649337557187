// import { IResponseNote } from "@common/components";
import { TUserResponse } from "@auth/types";
import { ActionMap, TCountryResponse } from "@common/types";
import { TFederationResponse } from "@federation/hooks";
import { ThemeType } from "@theme";

export interface IAppState {
    theme: ThemeType["mode"];
    user?: TUserResponse;
    authenticated: boolean;
    countries: TCountryResponse[];
    federations: TFederationResponse[];
};

export enum EAppActionType {
    toggleTheme = "TOGGLE_THEME",
    authenticate = "AUTHENTICATE",
    unauthenticate = "UNAUTHENTICATE",
    setCountries = "SET_COUNTRIES",
    setFederations = "SET_FEDERATIONS"
};

interface IAppActionPayload {
    [EAppActionType.toggleTheme]: undefined;
    [EAppActionType.authenticate]: TUserResponse;
    [EAppActionType.unauthenticate]: undefined;
    [EAppActionType.setCountries]: TCountryResponse[];
    [EAppActionType.setFederations]: TFederationResponse[];
};

export type TAppAction = ActionMap<
    IAppActionPayload
>[keyof IAppActionPayload];

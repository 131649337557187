import { ILayoutRoute } from "@common/types";
import { NewTeam } from "@team/pages";
import { lazy } from "react";
const Teams = lazy(() => import("@team/pages").then(({
    Teams
}) => ({default: Teams})));

export type TTeamRoutes = "teams"
    | "newTeam"; // | "oneTeam";

export const teamRoutes: Record<TTeamRoutes, ILayoutRoute> = {
    teams: {
        _id: 1,
        patterns: [""],
        url: "/teams",
        element: Teams,
        protect: true,
        urlBuilder: () => "/teams"
    },
    newTeam: {
        _id: 2,
        patterns: ["/new"],
        url: "/teams/new",
        element: NewTeam,
        protect: true,
        urlBuilder: () => "/teams/new"
    }
};

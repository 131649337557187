import { IResponseNote } from "@common/components";
import { ActionMap } from "@common/types";

export interface INotesState {
    responseNotes: IResponseNote[];
};

export enum ENotesActionType {
    addResponseNote = "ADD_RESPONSE_NOTE",
    dismissResponseNote = "DISMISS_RESPONSE_NOTE",
};

interface INotesActionPayload {
    [ENotesActionType.addResponseNote]: IResponseNote;
    [ENotesActionType.dismissResponseNote]: string;
};

export type TNotesAction = ActionMap<
    INotesActionPayload
>[keyof INotesActionPayload];

import { Box, Button, Form, IField } from "@common/components";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ITeamFederationFormInputs, TTeamFederationFormProps } from "./TeamFederationForm.types";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TFetchTeamsData, useFetchTeams } from "@team/hooks";
import { TSelectProps } from "@common/components/Select/Select.types";
import { useAppState } from "@app/context";

export const TeamFederationForm: FunctionComponent<TTeamFederationFormProps> = ({
    team,
    refresh,
    ...props
}) => {
    const appState = useAppState();
    const [currentValues, setCurrentValues] = useState<
        DeepPartialSkipArrayKey<ITeamFederationFormInputs>
    >();
    const [teamData, setTeamData] = useState<TFetchTeamsData>({
        method: "PATCH",
        trigger: false,
        _teamId: team?._id
    });
    const {
        code
    } = useFetchTeams(teamData);
    const federations: TSelectProps["options"] | undefined = useMemo(() => {
        if (appState.federations.length < 1) return;
        return appState.federations.map((fed) => ({
            value: fed._id,
            label: fed.acronym
        }));
    }, [appState.federations]);
    const fields: IField<ITeamFederationFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            mode: "select",
            defaultValue: team?._federationId,
            validations: {
                required: "teams must belong to a federation 😐"
            },
            name: "_federationId",
            modeProps: {
                placeholder: "FEDERATION",
            },
            select: {
                allowNull: false,
                options: federations
            }
        }];
        return _fields;
    }, [team, federations]);
    const hasChanged = useMemo(() => {
        if (team?._federationId !== currentValues?._federationId) return true;
        return false;
    }, [team, currentValues]);
    const onSubmit: SubmitHandler<ITeamFederationFormInputs> = useCallback(
        async (payload) => {
            if (!hasChanged) return;
            setTeamData((prev) => ({
                ...prev,
                data: {
                    _federationId: payload._federationId
                },
                _teamId: team?._id,
                trigger: true
            }));
        }, [hasChanged, team]);
    useEffect(() => {
        if (!code) return;
        setTeamData((prev) => ({
            ...prev, 
            trigger: false
        }));
        if (code === 200) {
            refresh && refresh();
        }
    }, [code, refresh]);
    return (
        <Box
            full
            direction="row"
            style={{
                padding: 0,
                margin: "0 0 1rem 0"
            }}>
            {federations && (<React.Fragment>
                <Form
                    aria-label="team-federation-form"
                    name={`team-federation-form-${team?.code || "new"}`}
                    fields={fields}
                    buttons={[]}
                    onSubmit={onSubmit}
                    setCurrentValues={setCurrentValues}
                    style={{width: "50%"}}
                    {...props} />
                <Button
                    disabled={!hasChanged}
                    style={{margin: "0 auto"}}
                    label="change"
                    caps
                    size="sm"
                    type="submit"
                    form={`team-federation-form-${team?.code || "new"}`}
                    nature="option"
                    loader={{
                        loaderSize: "sm",
                        speed: "fast",
                        loaderLength: "regular",
                        variant: "rainbow"
                    }} />
            </React.Fragment>)}
        </Box>
    );
};

import { IResponseNote } from "@common/components";
import { Dispatch } from "react";
import { ENotesActionType, TNotesAction } from "./NotesContext.types";

export const addResponseNote = (
    note: IResponseNote,
    dispatch: Dispatch<TNotesAction>
): void => {
    note = {
        ...note,
        hash: Math.random().toString(16).slice(2)
    };
    dispatch({
        type: ENotesActionType.addResponseNote,
        payload: note
    });
};

export const dismissResponseNote = (
    hash: IResponseNote['hash'],
    dispatch: Dispatch<TNotesAction>
): void => {
    dispatch({
        type: ENotesActionType.dismissResponseNote,
        payload: hash ? hash : "generic?"
    });
};

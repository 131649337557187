import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TFilteredMatchesTableProps } from "./FilteredMatchesTable.types";
import { Box, HorizontalSlider, Loader } from "@common/components";
import { TStringSliderObject, useEndlessDateSlider, useEndlessSlider } from "@common/hooks";
import { MatchStatusesEnum } from "@common/enums";
import { TFetchMatchesData, TMatchResponse, useFetchMatches } from "@match/hooks";
import { ITableHeader, ITableRow, Table } from "@common/components/Table";
import { GameMatchToggler } from "@games-api/components";

export const FilteredMatchesTable: FunctionComponent<TFilteredMatchesTableProps> = ({
    edition,
    game,
    filter = "date",
    refresh, 
    ...props
}) => {
    const [filteredMatches, setFilteredMatches] = useState<TMatchResponse[]>();
    const [matchesData, setMatchesData] = useState<TFetchMatchesData>({
        method: "GET",
        trigger: false
    });
    const {
        code,
        body,
        loading
    } = useFetchMatches(matchesData);
    const startDate: Date | undefined = useMemo(() => {
        if (filter !== "date") return;
        let _start: Date | undefined = undefined;
        let _end: Date | undefined = undefined;
        if (edition?.matches && edition.matches.length > 0) {
            _start = new Date(new Date(
                edition.matches[0].startsAt
            ).toDateString());
            _end = new Date (new Date(
                edition.matches[edition.matches.length - 1].startsAt
            ).toDateString());
        }
        const today = new Date(new Date().toDateString());
        if (!_start || !_end) return today;
        if (_start.getTime() > today.getTime()) return _start;
        if (today.getTime() > _end.getTime()) return _end;
        return today;
    }, [edition, filter]);
    const {
        current: currentDate,
        prev: previousDate,
        next: nextDate
    } = useEndlessDateSlider(startDate || new Date());
    const items: {
        label: string;
        value: string | MatchStatusesEnum;
    }[] | undefined = useMemo(() => {
        if (!filter) return;
        if (filter === "date") return;
        const _items: typeof items = [];
        for (const match of edition?.matches || []) {
            if (_items.map(_item => _item.value).includes(match[filter])) continue;
            _items.push({
                label: match[filter].toUpperCase(),
                value: match[filter]
            });
        }
        return _items;
    }, [filter, edition]);
    const {
        current: currentItem,
        prev: previousItem,
        next: nextItem
    } = useEndlessSlider<TStringSliderObject>(items);
    const filteredMatchRows: ITableRow[] = useMemo(() => {
        if (!game) return [];
        if (!filteredMatches || filteredMatches.length === 0) return [];
        const _rows: typeof filteredMatchRows = filteredMatches.filter(
            (_match) => _match.status === MatchStatusesEnum.PLANNED
        ).map((_match, index) => {
            const _gameMatch = game?.matches?.find(
                (_gameMatch) => _gameMatch.match?._matchId === _match._id
            );
            return {
                values: [
                    `${_match.home?.code.toUpperCase() || "TBD"} - ${_match.away?.code.toUpperCase()  || "TBD"}`,
                    // REPLACE THIS BUTTON WITH A SPECIFIC COMPONENT 
                    // FOR ADDING/REMOVING GAMEMATCHES!! 
                    <GameMatchToggler
                        key={index}
                        game={game}
                        gameMatch={_gameMatch}
                        match={_match}
                        refresh={refresh}
                        caps
                        style={{
                            margin: "0 auto"
                        }} />
                ]
            };});
        return _rows;
    }, [game, filteredMatches, refresh]);
    const filteredMatchHeaders: ITableHeader[] = useMemo(() => {
        const _headers: typeof filteredMatchHeaders = [{
            title: "home - away",
            alignment: "center",
            width: "60%"
        }, {
            title: "action",
            alignment: "center",
            width:"40%"
        }];
        return _headers;
    }, []);
    useEffect(() => {
        if (!code) return;
        setMatchesData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 200) {
            setFilteredMatches(body as TMatchResponse[]);
        }
    }, [code, body]);
    useEffect(() => {
        if (!currentDate) return;
        if (!edition) return;
        if (filter !== "date") return;
        setMatchesData((prev) => ({
            ...prev,
            method: "GET",
            qs: `date=${currentDate}&_editionId=${edition?._id}&status=${MatchStatusesEnum.PLANNED}`,
            trigger: true
        }));
    }, [currentDate, filter, edition]);
    useEffect(() => {
        if (!currentItem) return;
        if (!edition) return;
        if (filter === "date") return;
        setMatchesData((prev) => ({
            ...prev,
            method: "GET",
            qs: `${filter}=${currentItem.value}&_editionId=${edition?._id}&status=${MatchStatusesEnum.PLANNED}`,
            trigger: true
        }));
    }, [currentItem, filter, edition]);
    return (
        <Box
            full
            direction="column"
            stylesFrom={{
                lg: {
                    justifyContent: "center",
                    flexDirection: "row"
                }
            }}
            style={{
                justifyContent: "start",
                alignItems: "center",
                position: "relative",
                minHeight: "30rem",
                margin: "1rem 0 0 0",
                padding: 0
            }}>
            {filter === "date" && (
                <HorizontalSlider 
                    layer={2}
                    current={currentDate?.toLocaleDateString(undefined, {
                        month: "short",
                        year: "numeric",
                        day: "2-digit"
                    }) || ""}
                    onPrev={previousDate}
                    onNext={nextDate} />
            )}
            {filter !== "date" && (
                <HorizontalSlider
                    layer={2}
                    current={currentItem?.label || ""}
                    onPrev={previousItem}
                    onNext={nextItem} />
            )}
            {loading ? (
                <Loader />
            ) : (
                <Table
                    aria-label="filtered matches table"
                    rows={filteredMatchRows}
                    headers={filteredMatchHeaders} />
            )}
        </Box>
    );
};
import React, { FunctionComponent, useEffect, useState } from "react";
import { TGameMatchPanelProps } from "./GameMatchPanel.types";
import { Box, HorizontalSlider } from "@common/components";
import { useEndlessSlider } from "@common/hooks";
import { TCompetitionResponse, TFetchCompetitionsData, useFetchCompetitions } from "@competition/hooks";
import { FilteredMatchesTable, TFilterType } from "@match/components";
import { TEditionResponse, TFetchEditionsData, useFetchEditions } from "@edition/hooks";
import { GameMatchFilterPanel } from "../GameMatchFilterPanel";

export const GameMatchPanel: FunctionComponent<TGameMatchPanelProps> = ({
    game,
    refresh,
    style,
    ...props
}) => {
    const [filter, setFilter] = useState<Exclude<TFilterType, "status">>("date");
    const [competitions, setCompetitions] = useState<TCompetitionResponse[]>();
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        method: "GET",
        trigger: true
    });
    const {
        code: competitionCode,
        body: competitionBody
    } = useFetchCompetitions(competitionData);
    const {
        current: competition,
        next: nextCompetition,
        prev: previousCompetition
    } = useEndlessSlider<TCompetitionResponse>(
        competitions
    );
    const [editions, setEditions] = useState<TEditionResponse[]>();
    const [editionData, setEditionData] = useState<TFetchEditionsData>({
        trigger: false,
        method: "GET"
    });
    const {
        code: editionCode,
        body: editionBody
    } = useFetchEditions(editionData);
    const {
        current: edition,
        next: nextEdition,
        prev: previousEdition
    } = useEndlessSlider<TEditionResponse>(
        editions
    );
    useEffect(() => {
        if (!competitionCode) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (competitionCode === 200) {
            setCompetitions(competitionBody as TCompetitionResponse[]);
        }
    }, [competitionCode, competitionBody]);
    useEffect(() => {
        if (competition) {
            setEditionData((prev) => ({
                ...prev,
                qs: `_competitionId=${competition.id || competition._id}`,
                trigger: true
            }));
        }
    }, [competition]);
    useEffect(() => {
        if (!editionCode) return;
        setEditionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (editionCode === 200) {
            setEditions((editionBody as TEditionResponse[])
                .sort((_editionA, _editionB) => new Date(
                    _editionB.createdAt
                ).getTime() - new Date(
                    _editionA.createdAt
                ).getTime()
                )
            );
        }
    }, [editionBody, editionCode]);
    return (
        <Box
            full
            direction="column"
            style={{
                padding: 0,
                margin: "2.5rem 0 1rem 0",
                alignItems: "center",
                ...style
            }}
            {...props}>
            <HorizontalSlider
                current={competition?.name || "loading..."}
                onNext={nextCompetition}
                onPrev={previousCompetition} />
            <HorizontalSlider
                layer={2}
                current={edition?.name || ""}
                onNext={nextEdition}
                onPrev={previousEdition} />
            <GameMatchFilterPanel
                filter={filter}
                setFilter={setFilter} />
            {edition && (<FilteredMatchesTable
                refresh={refresh}
                game={game}
                filter={filter}
                edition={edition} />)}
        </Box>
    );
};

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { ICompetitionFormInputs, TCompetitionFormProps } from "./CompetitionForm.types";
import { useAppState } from "@app/context";
import { DeepPartialSkipArrayKey, SubmitHandler } from "react-hook-form";
import { TSelectProps } from "@common/components/Select/Select.types";
import { useNavigate } from "react-router";
import { TAvatarBody } from "@common/components/Avatar";
import { TCompetitionResponse, TFetchCompetitionAvatarData, TFetchCompetitionsData, useFetchCompetitionAvatars, useFetchCompetitions } from "@competition/hooks";
import { Avatar, Form, IField, IFormButton } from "@common/components";
import { TeamNaturesEnum } from "@team/enums";
import { competitionRoutes } from "@competition/routes";

export const CompetitionForm: FunctionComponent<TCompetitionFormProps> = ({
    refresh,
    competition,
    ...props
}) => {
    const appState = useAppState();
    const [currentValues, setCurrentValues] = useState<DeepPartialSkipArrayKey<ICompetitionFormInputs>>();
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState<TAvatarBody | undefined>(
        competition && competition.avatar
            ? {avatar: competition.avatar, file: undefined}
            : undefined
    );
    const [competitionData, setCompetitionData] = useState<TFetchCompetitionsData>({
        method: "POST",
        trigger: false
    });
    const {
        code,
        body,
        loading
    } = useFetchCompetitions(competitionData);
    const [avatarData, setAvatarData] = useState<TFetchCompetitionAvatarData>({
        method: "POST",
        trigger: false
    });
    const {
        code: avatarCode,
        loading: avatarLoading
    } = useFetchCompetitionAvatars(avatarData);
    const federations: TSelectProps["options"] = useMemo(() => {
        if (!appState.federations) return [];
        return appState.federations.map((fed) => ({
            value: fed._id,
            label: fed.acronym
        }));
    }, [appState.federations]);
    const fields: IField<ICompetitionFormInputs>[] = useMemo(() => {
        const _fields: typeof fields = [{
            row: 1,
            mode: "input",
            name: "name",
            validations: {
                required: "name is required 😐",
            },
            defaultValue: competition?.name || "",
            modeProps: {
                placeholder: "COMPETITION NAME"
            }
        }, {
            row: 2,
            mode: "textarea",
            name: "description",
            validations: {
                required: "description is required 😐"
            },
            defaultValue: competition?.description || "",
            modeProps: {
                rows: 3,
                placeholder: "DESCRIPTION OR LONG NAME"
            }
        }, {
            row: 3,
            name: "teamNature",
            mode: "select",
            validations: {
                required: "a team must have a nature 😐"
            },
            defaultValue: competition?.teamNature || "",
            modeProps: {
                placeholder: "NATURE"
            },
            select: {
                options: Object.entries(TeamNaturesEnum).map(
                    ([name, value]) => ({
                        label: `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
                        value: value
                    })
                ),
                allowNull: false
            }
        }];
        if (!competition) {
            _fields.push({
                row: 3,
                name: "_federationId",
                mode: "select",
                defaultValue: "",
                modeProps: {
                    placeholder: "FEDERATION"
                },
                select: {
                    options: federations,
                    allowNull: false
                }
            });
        }
        return _fields;
    }, [federations, competition]);
    const hasAvatarChanged = useMemo(() => {
        if (!competition) return true;
        if (avatar?.file) return true;
        return false;
    }, [competition, avatar?.file]);
    const hasChanged = useMemo(() => {
        if (!competition) return true;
        if (!currentValues) return true;
        for (const key in currentValues) {
            if (
                currentValues[key as keyof ICompetitionFormInputs]
                !== competition[key as keyof ICompetitionFormInputs]
            ) return true;
        }
        return false;
    }, [competition, currentValues]);
    const buttons: IFormButton[] = useMemo(() => {
        const _buttons: typeof buttons = [{
            row: 1,
            label: competition ? "update" : "create",
            size: "sm",
            caps: true,
            type: "submit",
            loading: loading || avatarLoading,
            loader: {
                loaderSize: "sm",
                speed: "fast",
                variant: "rainbow",
                loaderLength: "regular"
            },
            disabled: !hasChanged && !hasAvatarChanged
        }];
        return _buttons;
    }, [
        loading,
        avatarLoading,
        competition,
        hasChanged,
        hasAvatarChanged
    ]);
    const onSubmit: SubmitHandler<ICompetitionFormInputs> = useCallback(
        async (payload) => {
            if (!competition) {
                setCompetitionData((prev) => ({
                    ...prev,
                    data: {
                        ...payload,
                        teamNature: payload.teamNature as TeamNaturesEnum
                    },
                    trigger: true
                }));
                return;
            }
            if (hasChanged) {
                setCompetitionData((prev) => ({
                    ...prev,
                    data: {
                        name: payload.name !== competition.name
                            ? payload.name
                            : undefined,
                        description: payload.description !== competition.description
                            ? payload.description
                            : undefined,
                        teamNature: payload.teamNature !== competition.teamNature
                            ? payload.teamNature as TeamNaturesEnum
                            : undefined
                    },
                    trigger: true,
                    method: "PATCH",
                    _competitionId: competition._id
                }));
            } else if (hasAvatarChanged) {
                setAvatarData((prev) => ({
                    ...prev,
                    method: avatar?.avatar
                        ? "PATCH"
                        : "POST",
                    _avatarId: avatar?.avatar
                        ? avatar.avatar._id
                        : undefined,
                    data: {
                        _competitionId: competition._id,
                        file: avatar?.file,
                        avatar: avatar?.avatar
                    },
                    trigger: true
                }));
            }
        }, [avatar, competition, hasChanged, hasAvatarChanged]);
    useEffect(() => {
        if (!code) return;
        setCompetitionData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (code === 201) {
            if (!avatar?.file) {
                navigate(competitionRoutes.competitions.url);
                return;
            }
            setAvatarData((prev) => ({
                ...prev,
                data: {
                    _competitionId: (body as TCompetitionResponse)._id,
                    file: avatar.file,
                    avatar: avatar.avatar
                },
                trigger: true
            }));
        }
        if (code === 200) {
            if (!avatar?.file) {
                refresh && refresh();
                return;
            }
            setAvatarData((prev) => ({
                ...prev,
                method: avatar?.avatar
                    ? "PATCH"
                    : "POST",
                _avatarId: avatar?.avatar
                    ? avatar.avatar._id
                    : undefined,
                data: {
                    _competitionId: (body as TCompetitionResponse)._id,
                    file: avatar?.file,
                    avatar: avatar?.avatar
                },
                trigger: true
            }));
        }
    }, [code, body, navigate, avatar, refresh]);
    useEffect(() => {
        if (!avatarCode) return;
        setAvatarData((prev) => ({
            ...prev,
            trigger: false
        }));
        if (avatarCode === 201 || avatarCode == 200) {
            refresh && refresh();
            navigate(competitionRoutes.competitions.url, {
                replace: true
            });
        }
    }, [avatarCode, navigate, refresh]);
    const _Avatar = useMemo(() => {
        return (
            <Avatar
                edit
                item="competition"
                avatar={avatar}
                setAvatar={setAvatar}
                size="xxl"
                style={{
                    margin: "2rem 0 1rem 0"
                }} />
        );
    }, [avatar]);
    return (
        <React.Fragment>
            {_Avatar}
            <Form
                aria-label="competition-form"
                name={`competition form ${competition?._id || "new competition"}`}
                fields={fields}
                buttons={buttons}
                onSubmit={onSubmit}
                setCurrentValues={setCurrentValues}
                {...props} />
        </React.Fragment>
    );
};

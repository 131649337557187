import styled from "styled-components";
import { Box } from "../Box";
import { TItemBoxProps } from "./ItemBox.types";
import { TSize, breakpoints } from "@theme";
import { parseTo } from "@utils/parsers";

export const StyledItemBox = styled(Box).attrs<TItemBoxProps>({
    direction: "column",
    min: "22rem",
    max: "22rem",
    full: false,
    loose: true
})`
    padding: 0.5rem
    margin: 0.5rem;
    position: relative;
    background-color: ${props => props.theme.palette.backgroundContext};
    border: 3px solid ${props => props.theme.palette.borderContext};
    box-shadow: 4px 4px 0px 0px #bbb inset, -4px -4px 0px 0px #666 inset;
    ${props => props.stylesFrom
        ? Object.entries(props.stylesFrom).map(
            ([breakpoint, styles]) => {
                return `@media screen and ${breakpoints[breakpoint as TSize]} {
                    ${Object.entries(styles).map(
        ([property, value]) => {
            return `${parseTo(property, "kebab")}: ${value};`;
        }
    )}
                }`;
            }
        ) : ""
}
`;
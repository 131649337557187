import { useCallback } from 'react';
import { IUseLocalStorage, TUseLocalStorageData } from './useLocalStorage.types';

export function useLocalStorage<T extends object | string | boolean>({
    key, 
    initialValue, 
    cleanUp,
}: TUseLocalStorageData<T>): IUseLocalStorage<T> {
    const getCurrent = useCallback(() => {
        if (!window) return initialValue;
        const item = window.localStorage.getItem(key);
        try {
            if (item) {
                const _json = JSON.parse(item);
                return _json; 
            }
            return initialValue;
        } catch (error) {
            console.warn(`>>> 📛 defaulting to initial value, error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    }, [key, initialValue]);
    const setCurrent = useCallback((value: T) => {
        if (!window) return;
        window.localStorage.setItem(
            key,
            JSON.stringify(value)
        );
        return;
    }, [key]);





    // // Pass initial state function to useState so logic is only executed once
    // const [storedValue, setStoredValue] = useState<T>(readValue);

    // // Return a wrapped version of useState's setter function that ...
    // // ... persists the new value to localStorage.
    // const setValue = (value: T) => {
    //     // Prevent build error "window is undefined" but keep keep working
    //     if (typeof window == 'undefined') {
    //         console.warn(
    //             `Tried setting localStorage key “${key}” even though environment is not a client`
    //         );
    //     }

    //     try {
    //         // Allow value to be a function so we have the same API as useState
    //         const newValue = value instanceof Function ? value(storedValue) : value;

    //         // Save to local storage
    //         window.localStorage.setItem(key, JSON.stringify(newValue));

    //         // Save state
    //         setStoredValue(newValue);

    //         // We dispatch a custom event so every useLocalStorage hook are notified
    //         window.dispatchEvent(new Event('local-storage'));
    //     } catch (error) {
    //         console.warn(`Error setting localStorage key “${key}”:`, error);
    //     }
    // };

    // useEffect(() => {
    //     setStoredValue(readValue());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     return () => {
    //         if (cleanUp) {
    //             window.localStorage.removeItem(key);
    //         }
    //     }
    // }, [cleanUp, key, readValue]);

    // useEffect(() => {
    //     const handleStorageChange = () => {
    //         setStoredValue(readValue());
    //     };

    //     // this only works for other documents, not the current one
    //     window.addEventListener('storage', handleStorageChange);

    //     // this is a custom event, triggered in writeValueToLocalStorage
    //     window.addEventListener('local-storage', handleStorageChange);

    //     return () => {
    //         window.removeEventListener('storage', handleStorageChange);
    //         window.removeEventListener('local-storage', handleStorageChange);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return {
        // current: state, 
        setCurrent: setCurrent,
        getCurrent: getCurrent
    };
};

import { setCountries, useAppDispatch } from "@app/context";
import { useFetch } from "@common/hooks";
import { endpoints } from "@common/endpoints";
import { useEffect, useState } from "react";
import { IFetchCountries, TFetchCountriesData } from "./useFetchCountries.types";
import { TCountryResponse } from "@common/types";

export function useFetchCountries(): IFetchCountries {
    const appDispatch = useAppDispatch();
    const [hookData] = useState<TFetchCountriesData>({
        endpointURI: endpoints.countries.url,
        method: "GET",
        inform: false,
        trigger: true,
        auth: false
    });
    const {
        response,
        body,
        code,
        fetch,
        loading,
        error
    } = useFetch<Record<string, never>, TCountryResponse>(hookData);
    useEffect(() => {
        if (response?.status === 200 && body) {
            setCountries(
                body as TCountryResponse[],
                appDispatch
            );
        }
    }, [response, body, appDispatch]);
    return {
        response,
        body,
        code,
        fetch,
        loading,
        error
    };
};

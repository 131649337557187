import { ILayoutRoute } from "@common/types";
import { NewCompetition } from "@competition/pages";
import { lazy } from "react";
const Competitions = lazy(() => import("@competition/pages").then(({
    Competitions
}) => ({default: Competitions})));

export type TCompetitionRoutes = "competitions" 
    | "newCompetition";

export const competitionRoutes: Record<TCompetitionRoutes, ILayoutRoute> = {
    competitions: {
        _id: 1,
        patterns: [""],
        url: "/competitions",
        element: Competitions,
        protect: true,
        urlBuilder: () => "/competitions"
    },
    newCompetition: {
        _id: 2,
        patterns: ["/new"],
        url: "/competitions/new",
        element: NewCompetition,
        protect: true,
        urlBuilder: () => "/competitions/new"
    }   
};

import styled from "styled-components";
import { TSelectProps } from "./Select.types";
import { fontSizes } from "@theme";

export const StyledSelect = styled.select<TSelectProps>`
    width: 100%;
    margin: 0;
    background-color: ${props => props.theme.palette.inputBackground};
    color: ${props => props.theme.palette.itemDarkText};
    padding: 0.5rem 0.8rem 0.5rem 1rem;
    font-size: ${props => props.fontSize
        ? fontSizes[props.fontSize]
        : fontSizes.sm};
    font-family: ${props => props.theme.fonts.main};
    border: 2px solid ${props => props.theme.palette.borderTheme};
    ::-ms-expand {
        display: none;
    }
    -mos-appearance: none;
    -webkit-appearance: none;
    box-shadow: 5px 5px 0px 0px #22222299 inset, -5px -5px 0px 0px #22222266 inset;
`;

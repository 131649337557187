import React, { FunctionComponent } from "react";
import { Container, ItemBox, Text, Title } from "@common/components";
import { TPageProps } from "@common/types";
import { MatchForm } from "@match/components";

export const NewMatch: FunctionComponent<TPageProps> = (props) => {
    return (
        <Container
            style={{
                alignItems: "center"
            }}>
            <Text
                component="p"
                size="lg"
                variant="special"
                text="new"
                style={{
                    marginBottom: "-1.5rem"
                }} />
            <Title
                text="Match"
                size="xxl" />
            <ItemBox
                style={{
                    margin: "2rem 0"
                }}>
                <MatchForm />
            </ItemBox>
        </Container>
    );
};

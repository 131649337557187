export type TDevice = "mobile" | "tablet" | "pad" | "laptop" | "desktop";
export type TSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"; 

export const devices: Record<TDevice, string> = {
    mobile: "576px",
    tablet: "768px",
    pad: "992px",
    laptop: "1200px",
    desktop: "1400px"
};

export const breakpoints: Record<TSize, string> = {
    xs: `(min-width: 1px)`,
    sm: `(min-width: ${devices.mobile})`,
    md: `(min-width: ${devices.tablet})`,
    lg: `(min-width: ${devices.pad})`,
    xl: `(min-width: ${devices.laptop})`,
    xxl: `(min-width: ${devices.desktop})`,
};
